import React from'react';

const ArticleParagraph = ({keyId, paragraphContent}) => (
    <>
    <p paragraph-key={keyId} className="article_paragraph">
        <span>{paragraphContent}</span>
    </p>
    </>
);

export default ArticleParagraph;