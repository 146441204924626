import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
}from 'react-router-dom'
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ArticlesListPage from './pages/ArticlesListPage';
import ArticlePage from './pages/ArticlePage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import NotFoundPage from './pages/NotFoundPage';
import NavBar from './NavBar';
import Footer from './Footer'
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
          <Switch>
              <Route path="/" component={HomePage} exact/>
              <Route path="/about" component={AboutPage} />
              <Route path ="/articles-list" component={ArticlesListPage} />
              <Route path="/article/:name" component={ArticlePage} />
              <Route path="/privacy-cookie-policy" component={PrivacyPolicyPage}/>
              <Route component={NotFoundPage}/>
          </Switch>
          
      </div>
      <Footer />
    </Router>
    
  );
}

export default App;
