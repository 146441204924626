import React from 'react';
import ResponsiveArticleRow from '../components/ResponsiveArticleRow';
import ButtonLink from '../components/ButtonLink'
import {Helmet} from 'react-helmet';

const latestArticleData = 
    [{
        "link" : "/article/25-things-you-must-know-italy",
        "title" : "25 Things you must know before visiting Italy: Your Ultimate Italian Guide",
        "imageUrl" : "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_MAIN.jpeg"
    },{
        "link" : "/article/recommended-backpacking-gear",
        "title" : "Recommended Backpacking Gear: Quality findings on a budget",
        "imageUrl" : "https://imagestravelblog.s3.us-east-2.amazonaws.com/RBG_3/RBG_3_MAIN.png"
    },{
        "link" : "/article/11-free-budget-friendly-experiences-youll-love-in-cancun",
        "title" : "11 Free and Budget friendly experiences you’ll love in Cancun",
        "imageUrl" : "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_MAIN.jpg"
    }];

const HomePage = () => (
    <>
    <Helmet>
        <meta property="og:title" content="Dos Outdoors"></meta>
        <meta property="og:description" content="Two on the Go!"></meta>
        <meta property="og:image" content="https://imagestravelblog.s3.us-east-2.amazonaws.com/en_cover_pic.png"></meta>
        {/* <meta property="og:url" content="https://dosoutdoors.com"></meta> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:site_name" content="Dos Outdoors"></meta>
        {/* Twiter */}
        <meta name="twitter:title" content="Dos Outdoors"></meta>
        <meta name="twitter:image" content="https://imagestravelblog.s3.us-east-2.amazonaws.com/en_cover_pic.png"></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <title>Dos Outdoors | Two on the go Travel Blog</title>
    </Helmet>
    <div className="home_image_container">
        
            <div className="home_main_image">
            <div className="main_logo">
                        <img className="dosoutddors_logo_home" src="https://imagestravelblog.s3.us-east-2.amazonaws.com/logo+png-01.png"></img>
                    </div>
            <div className="image_filter">
                <div className="main_logo_conatiner">
                    
                </div>
            </div>
        </div>
    </div>
    <div id="page-body">
        
        <div className="intro_section">
            <div className="welcome_section">
                <div className="welcome_left">
                <div class="aspect_container">
                    <div class="aspectBox">
                        <a href="/about">
                            <img src="https://imagestravelblog.s3.us-east-2.amazonaws.com/dostogetherjpeg.jpg"></img>
                        </a>
                    </div>
                </div>

                </div>
                <div className="welcome_right">
                    <p className="article_paragraph responsive_reading_font"><strong>Welcome to our blog!</strong></p>
                    <p className="article_paragraph responsive_reading_font">We are a couple who loves to travel the world and embark on outdoor adventures. We are travelers who enjoy cultural experiences, authentic food, and breathtaking views. We've put together our experiences tips and stories to help inspire you on your next adventures. </p>
                </div>
                
            </div>

        </div>
        <h3 className="hand_written_header">Latest-Posts</h3>
        
        <ResponsiveArticleRow articles={latestArticleData}/> 
        <div className="more_art_btn_cont">
            <ButtonLink name="Browse All Articles" link="/articles-list"/>
        </div>
    </div>
    
    </>
);

export default HomePage;