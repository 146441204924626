import React from 'react';

const PrivacyPolicyPage = () => (
    <>
        <div id="page-body">
            <h1 className="hand_written_header">Privacy Policy</h1>
            <p className="article_paragraph"> This blog does not share personal information with third parties nor do we store any information about your visit to this blog other than to analyze and optimize your content and reading experience through the use of cookies.</p>
            <h1 className="hand_written_header">Cookie Policy</h1>
            <p className="article_paragraph">
            This site uses cookies – small text files that are placed on your machine to help detrermine popularity of articles and better understand activity on dosoutdoors.com. In general, cookies are used to retain user preferences and provide anonymised tracking data to third party applications like Google Analytics. As a rule, cookies will make your browsing experience better. However, you may prefer to disable cookies on this site and on others. The most effective way to do this is to disable cookies in your browser. We suggest consulting the Help section of your browser or taking a look at <a href="http://www.aboutcookies.org">the About Cookies website</a> which offers guidance for all modern browsers.
            </p>

            <h1 className="hand_written_header">Copyright</h1>
              <p className="article_paragraph">All photographs and content within this site are copyright protected except where specifically stated otherwise. No content or imagery may be re-used, copied, embedded or re-purposed without written consent</p>
            <h1 className="hand_written_header">Terms & Conditions</h1>
             <p className="article_paragraph">By using this site you do so at your own free will. All stories, tips and information contained are from personal experience and may have taken place some time ago. We accept no responsibility for any misleading or incorrect information and highly recommend for prices and details of third supplier items you seek out the most recent with them directly. Please take any advice within this website as a rough guide for your travels, As such, we make no representations or warranties of any kind, express or implied, about the accuracy, completeness, availability, suitability or readability in regards to the website or the information, products, services contained within it. You agree to use the information on this website for information purposes only and at your own risk. In no case will we be liable for any damage or loss due to using this website. Including, but not limited to, indirect or accidental loss of damage, any harm to profits or financials or any other personal damage through the use of this website. </p>
             <h1 className="hand_written_header">Amazon Associates Program</h1>
             <p className="article_paragraph">Dos Outdoors is a participant in the Amazon Services LLC Associates Program, an affiliate advertising program designed to provide a means for us to earn fees by linking to Amazon.com and affiliated sites</p>

             </div>
    </>
);

export default PrivacyPolicyPage;