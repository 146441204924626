import React from 'react';

const MoreBox = ({description, link}) =>(
    <>
        <div className="sideNoteContainer">
            <p className="sideNote" >
                <span class="sideNoteStart">More: </span><a href href={link}>{description}</a>
            </p>
        </div>
    </>
);

export default MoreBox;