import React from 'react';
import { Link } from 'react-router-dom';
import { IconName } from 'react-feather';
import pintLogo from './pinterest-logo-icon.png';
import Burger from './components/Burger';
import BurgerMenu from './components/BurgerMenu';

var InstagramIcon = require('react-feather/dist/icons/instagram').default;
var FacebookIcon = require('react-feather/dist/icons/facebook').default;
var TwitterIcon = require('react-feather/dist/icons/twitter').default;
// var PinterestIcon = require('react-feather/dist/icons/pinterest').default;

function RedirectaUrl(e){   
        window.open(e.currentTarget.dataset.redirectlink, '_blank').focus();
 }

const NavBar = () => {
    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    const useOnClickOutside = (ref, handler) => {
        React.useEffect(() => {
            const listener = event => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
            };
            document.addEventListener('mousedown', listener);
        
            return () => {
            document.removeEventListener('mousedown', listener);
            };
        },
        [ref, handler],
        );
    };
      return(
        <>
        <nav>
            <ul className="navBtn">
                <li>
                    <Link to="/">Home</Link>
                </li>
            </ul>
            <ul className="navBtn">
                <li>
                    <Link to="/about">about</Link>
                </li>
            </ul>
            <ul className="navBtn">
                <li>
                    <Link to="/articles-list">Articles</Link>
                </li>
            </ul> 
            <div className="social_block">
                <a data-redirectlink="https://www.instagram.com/dosoutdoors/" onClick={RedirectaUrl} href="#" target="_self">
                    <InstagramIcon />
                </a>
                <a data-redirectlink="https://www.facebook.com/Dos-Outdoors-104108798349465" onClick={RedirectaUrl} href="#" target="_self">
                    <FacebookIcon />
                </a>
                <a data-redirectlink="https://twitter.com/DosOutdoors" onClick={RedirectaUrl} href="#" target="_self">
                    <TwitterIcon />
                </a>
                <a data-redirectlink="https://www.pinterest.com/dosoutdoors" onClick={RedirectaUrl} href="#" target="_self">
                    <img className="pinterestLogo"src={pintLogo}></img>
                </a>
                <Burger open={open} setOpen={setOpen}/>
                <BurgerMenu open={open}  setOpen={setOpen}/>
            </div>
        </nav>
        </>
      );

      };

export default NavBar;