import React from 'react';
import { Link } from 'react-router-dom';
import ArticlesPageImageSquare from './ArticlesPageImageSquare';

const ArticlesList = ({ articles }) => (
    <>
    {articles.map((article, key) => (
       
            <Link className="article-list-item" key={key} to={`/article/${article.name}`}>
                <div className="article-list-image">
                    <ArticlesPageImageSquare articleLink={`/article/${article.name}`} articleImage={article.main_image} />
                </div>
                <div className="article-item-description">
                    <h3>{article.title}</h3>
                    <p className="preview_par">{article.content[0].substring(0,150)}...</p>
                </div>
            </Link>
      
    ))}
    </>
);
export default ArticlesList;