import React, {useState, useEffect} from 'react';
import ArticlesList from '../components/ArticlesList';
import NotFoundPage from './NotFoundPage';
import articleContent from './article-content';
import CommentsList from '../components/CommentsList';
import ArticleMainImage from '../components/ArticleMainImage';
import ArticleParagraph from '../components/ArticleParagraph';
import ArticleItem from '../components/ArticleItem';
import {Helmet} from 'react-helmet';



const ArticlePage = ({ match }) => {
        const name = match.params.name;
        const article = articleContent.find(article => article.name === name); 

        const [articleInfo, setArticleInfo] = useState({upvotes: 0, comments: [{username: "MadB055", text:"This is the BEst zBlog Ever!"}], article_images : [""]});
        let firebaseData = {};
        
        useEffect(() => {
            
            const fetchData = async () =>{
                const result = await fetch(`/api/articles/${name}`);
                const body = await result.json();
                // console.log("Jan27: "+body.article_text["1"]);
                setArticleInfo(body);
                // setArticleInfo({serializedArticle: objListToArray(body.article_images)})

            }
            fetchData();
            window.scrollTo(0, 0);
            //Comments Functionality
            // setArticleInfo({ upvotes: 3});
            // setArticleInfo({comments: [{username: "MadB055", text:"This is the BEst zBlog Ever!"},{username: "MadB055", text:"This is the BEst zBlog Ever!"}]})
        },[name]);

        if(!article){
            return <NotFoundPage />
        }

        const otherArticles = articleContent.filter(article => article.name !== name);
        return(
            <>
            <Helmet>
                <title>{article.title}</title>
                {/* Facebook */}
                <meta property="og:title" content={article.title}></meta>
                <meta property="og:description" content={article.content[0].substring(0,150)}></meta>
                <meta property="og:image" content={article.main_image}></meta>
                <meta property="og:url" content={window.location.href}></meta>
                <meta property="og:site_name" content="Dos Outdoors"></meta>
                {/* Twiter */}
                <meta name="twitter:title" content={article.title}></meta>
                <meta name="twitter:description" content={article.content[0].substring(0,150)}></meta>
                <meta name="twitter:image" content={article.main_image}></meta>
                <meta name="twitter:card" content="summary_large_image"></meta>
                
            </Helmet>
            <div id="page-body">
            <h1 className="responsive_hand_written_header">{article.title}</h1>
            <div className="manMetaContainer">
                <p className="main_image_meta">
                 <span>{articleInfo.title_sub}</span>
                </p>
            </div>
            <ArticleMainImage articleInfo={article}/>
            <div className="blurb_container">
                <p className="intro_blurb">{articleInfo.intro_paragraph}</p>
            </div>
            {/* {article.content.map((paragraph, key) =>
                <ArticleParagraph keyId={key} paragraphContent={paragraph}/>
            )} */}

            <div className="article_content_container">
                {article.article_body.map((item, key) =>
                    <ArticleItem key={key} props={item}/>
                )}
            </div>


            {/* <CommentsList comments={articleInfo.comments} /> */}

            <h3 className="hand_written_header">Other Articles:</h3>
            <ArticlesList articles={otherArticles} /> 
            </div>
            </>
        );
    }

export default ArticlePage;