import React from 'react';
import JsxParser from 'react-jsx-parser';
import ReactHtmlParser from 'react-html-parser';
import ArticleSubheading from './ArticleSubheading';
import AffiliatesDescription from './AffiliatesDescription';

const AfiliateItemSection =({itemTitle, itemLink, imageHTML, details,recommend, type, subtext}) =>{
   return(
   <>
    <a href={itemLink}>
        <ArticleSubheading subHeadingContent={itemTitle}/>
    </a>
    <div className="amz_img_container">
        {ReactHtmlParser(imageHTML)}
    </div>
    <AffiliatesDescription details={details} recommend={recommend} type={type} subtext={subtext} />
    </>
)};

export default AfiliateItemSection;