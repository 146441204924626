import React from 'react';

const Burger =({open, setOpen}) =>{
    let burgerClassName = open?'hamburger_icon cross':'hamburger_icon para';
    return(
    <>
        <button className={burgerClassName} onClick={() => setOpen(!open)}>
            <div className="bb_1"></div>
            <div className="bb_2"></div>
            <div className="bb_3"></div>
        </button>
    </>
    )
}

export default Burger;