import React from 'react';
import ArticleTextListItem from './ArticleTextListItem'

const ArticleTextList = ({listItems}) => {
    return(
    <>
        <ul className="article_text_list">
            {listItems.map((item, key) =>
                    <ArticleTextListItem key={key} props={item}/>                
            )}
        </ul>
    </>
    );

}

export default ArticleTextList;