import React from    'react';

const ArticleImage =({imageUrl,subtext}) =>(
    <>
        <img src={imageUrl} className="single_articlkeImage"></img>
        <p className="main_image_meta">
            <span>{subtext}</span>
        </p>
    </>
);
export default ArticleImage;