import React from 'react';
import ArticleParagraph from './ArticleParagraph';
import ArticleImage from './ArticleImage';
import ArticleSubheading from './ArticleSubheading';
import BoldPar from './BoldPar';
import ArticleTextList from './ArticleTextList'
import ArticleTextListSquare from './ArticleTextListSquare'
import SectionDivider from './SectionDivider'
import AfiliateItemSection from './AfiliateItemSection'
import MoreBox from './MoreBox';

const ArticleItem = ({key, props}) => {

    if(props.paragraph != null){
        return (<ArticleParagraph keyId={key} paragraphContent={props.paragraph}/>)
    }else if(props.image != null){
        return (<ArticleImage imageUrl={props.image} subtext={props.subtext}/>)
    }else if(props.subHeading != null){
        return(<ArticleSubheading subHeadingContent={props.subHeading} />)
    }else if(props.boldPar != null){
        return(<BoldPar paragraphContent={props.boldPar}/>)
    }else if(props.ArticleTextList){
        return(<ArticleTextList listItems={props.ArticleTextList}/>)
    }else if(props.ArticleTextListSquare){
        return(<ArticleTextListSquare listItems={props.ArticleTextListSquare}/>)
    }else if(props.SectionDivider){
        return(<SectionDivider dividerType={props.SectionDivider}/>)
    }else if(props.AAfiliateItem){
        return(<AfiliateItemSection itemTitle={props.title}  itemLink={props.url} imageHTML={props.imagehtml} details={props.details} recommend={props.recommend} type={props.type} subtext={props.subtext}/>)
    }else if(props.moreLink){
        return(<MoreBox description={props.description} link={props.link}/>)
    }
}

export default ArticleItem;