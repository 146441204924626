import React from 'react';
import {Helmet} from 'react-helmet';
import MapChart from "./MapChart";

const AboutPage = () => (
    <>
    <Helmet>
        <title>Dos Outdoors | About Us</title>
        <meta property="og:title" content="Dos Outdoors | About Us"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <meta property="og:description" content="Dos Outdoors | About Us"></meta>
        <meta property="og:image" content="https://imagestravelblog.s3.us-east-2.amazonaws.com/en_cover_pic.png"></meta>
    </Helmet>
    <div id="page-body">
        <h1 className="hand_written_header">About Us</h1>
        <div className="map_container">
            <MapChart />
        </div>
        <p className="article_paragraph">We are Esmeralda and Fernando, adventurous 30-something year old professional engineers based out of the United States. We love to travel and immerse ourselves into learning about different cultures, food, and experiences. </p>
        <p className="article_paragraph">We enjoy traveling together and strive to blaze a trail off the beaten track of tourist traps. We appreciate learning about the culture and history of the places we visit, allowing us to experience from the perspective of locals and why they call these places home. Our best experiences have always come from loving to live and feel like a local, leading us to find hidden gems and creating great memories. </p>
        <p className="article_paragraph">We both value learning about food and are not afraid to try new, exotic tastes and plates around the world because it also inspires our passion for cooking. We harmoniously coexist as a vegetarian and non vegetarian duo and love to learn from each other. When traveling we seek authentic, delicious cuisine, and innovative, amazing plant based creations.  </p>
        <p className="article_paragraph">Our goal is to help inspire your next trip and offer our genuine experience. At DosOutdoors you will find our take on experiences ranging from travel, backpacking, camping, dining as well as tips and recommendations for gear.</p>

    </div>
    </>
);

export default AboutPage;