import React from 'react';

const ResponsiveSquare = ({articleData, number}) =>(
    <>
        <a href={articleData[number].link}>
            <div className="aspect_container">
                <div className="aspectBox">
                    <img src={articleData[number].imageUrl}></img>          
                    <div className="aspectBox_text">
                        <span>{articleData[number].title}</span>
                    </div>
                </div>
            </div>
        </a>
    </>
);

export default ResponsiveSquare;