import React from 'react';

const Footer = () =>(
    <>
    <footer className="dos_outdoors_footer">
        <div className="footer_left_section">
            <p>© Copyright 2020-2021 Dos Outdoors</p>
            <p>All Right Reserved</p>
            <a href="/privacy-cookie-policy">
                <p>Cookie, Privacy/Terms & Conditions</p>
            </a>
        </div>
        <div className="footer_right_section">
            
        </div>
    </footer>
    </>
);

export default Footer;

