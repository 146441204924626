import React from 'react';
import articleContent from './article-content';
import ArticlesList from '../components/ArticlesList';
import {Helmet} from 'react-helmet';

const ArticlesListPage = () => (
    <>
    <Helmet>
        <meta property="og:title" content="Dos Outdoors | Articles"></meta>
        <meta property="og:url" content={window.location.href}></meta>
        <title>Dos Outdoors | Articles</title>
        <meta property="og:description" content="Dos Outdoors | Two on the Go!"></meta>
        <meta property="og:image" content="https://imagestravelblog.s3.us-east-2.amazonaws.com/en_cover_pic.png"></meta>
        <meta property="og:site_name" content="Dos Outdoors"></meta>
        {/* Twiter */}
        <meta name="twitter:title" content="Dos Outdoors"></meta>
        <meta name="twitter:image" content="https://imagestravelblog.s3.us-east-2.amazonaws.com/en_cover_pic.png"></meta>
        <meta name="twitter:card" content="summary_large_image"></meta>
        <title>Dos Outdoors | Two on the go Travel Blog</title>
    </Helmet>
    <div id="page-body">
    <h1 className="hand_written_header">Articles</h1>
    <ArticlesList articles={articleContent.reverse()} />
    </div>
    </>
);

export default ArticlesListPage;