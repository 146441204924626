import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
  Line,
  Sphere
} from "react-simple-maps";
import { PatternLines } from "@vx/pattern";
import TopoJson from './topojson.json';

// const geoUrl =
//   "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const geoUrl = TopoJson;

const highlighted = [
  "USA",
  "MEX",
  "ITA",
  "GBR",
  "CAN",
  "CHE"
];

function generateCircle(deg) {
  if (!deg) return [[-180, 0], [-90, 0], [0, 0], [90, 0], [180, 0]];
  return new Array(361).fill(1).map((d, i) => {
    return [-180 + i, deg];
  });
}

const MapChart = () => {
  return (
    <ComposableMap projection="geoEqualEarth">
      <PatternLines
        id="lines"
        height={6}
        width={6}
        stroke="#023144"
        strokeWidth={5}
        background="#F6F0E9"
        orientation={["diagonal"]}
      />
      <Sphere stroke="#DDD" />
      <Graticule stroke="#DDD" />
      <Geographies geography={geoUrl} stroke="#FFF" strokeWidth={0.5}>
        {({ geographies }) =>
          geographies.map(geo => {
            const isHighlighted =
              highlighted.indexOf(geo.properties.ISO_A3) !== -1;
            return (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill={isHighlighted ? "url('#lines')" : "#ffad8c"}
                onClick={() => console.log(geo.properties.ISO_A3)}
              />
            );
          })
        }
      </Geographies>
    </ComposableMap>
  );
};

export default MapChart;
