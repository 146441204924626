import React from 'react';
import BoldPar from './BoldPar';
import ArticleParagraph from './ArticleParagraph';

const AffiliatesDescription = ({ details,recommend, type, subtext}) =>{
    if(type === undefined || type === 1){
        return(
        <>
            <BoldPar paragraphContent={"Why we recommend it: "}/>
            <ArticleParagraph keyId={0} paragraphContent={recommend}/>
            <BoldPar paragraphContent={"Details: "}/>
            <ArticleParagraph keyId={0} paragraphContent={details}/>
            </>
        );
    }else{
        return(
        <>
        <p className="main_image_meta">
            <span>{subtext}</span>
        </p>
        
        </>
        )
    }
}

export default AffiliatesDescription;