const articles = [         
    {
        name: 'camping-in-bear-country',
        title: 'Camping in Bear Country; Your ultimate planning guide',
        main_image:"https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_MAIN.jpg",
        content: [`Welcome to your ultimate guide to feel reassured and safe in bear country, whether it’s camping or even backpacking these are all useful tips and tricks to know before spending a night in bear country.`],
        "article_body": [
            {
                "subHeading": "Know what bear country you’re going into"
            },
            {
                "paragraph": "Planning a trip to bear country requires you to first ask yourself, “what type of bear country am I going into?” Not all bear countries are the same everywhere you go, as there are different types of bears that inhabit these places and thus important to know in order to be prepared for a rare, but potential bear encounter. Around the world there are multiple types of bears, but for North America you will either be in brown (grizzly) or black bear country.  "
            },
            {
                "paragraph": "Brown bear country in this day and age consists of Canada, Alaska, Wyoming, Montana, and northern parts of Washington. These brown bears are most commonly known in North America as grizzly bears and are larger in size than black bears, weighing up to 1500 pounds depending on food availability. "
            },
            {
                "paragraph": "Black bear country today is from northern Florida going up to Canada and is the official bear found throughout the United States most commonly. Black bears can be found throughout most mountain ranges in the United States and can weigh up to 600 pounds.  "
            },
            {
                "subHeading": "Know proper plan of action in case of bear encounter"
            },
            {
                "paragraph": "Encountering a bear in the wild can be one of the most beautiful sites to take in and experience but at the same time should consist of proper action for the safety of everyone. Remember that bears do not want to encounter humans either and would like to also go on about their day without trouble. In the rare event of a bear encounter there are specific plans of actions that are recommended and have been proven best to ensure the safety of everyone.  "
            },
            {
                "ArticleTextList": ["Stand your ground, identify yourself calmly, and wave your hands slowly to let the bear understand you are a human and not a prey.", " Do not scream or make any high pitched sounds because a bear could interpret this as a threat or feel forced to attack.", " Do not do any rushed actions or try to run away quickly, because just as dogs like to chase people running away - so does a bear. ","If the bear is standing still make sure to move back slowly away to further distance yourself from the bear, do not turn your back to the bear, but instead walk back slowly facing it sideways, as this will give you a vantage point of the bear. ","Make sure to be extra careful if the encounter happens to be a bear with cubs as the aggression by a bear could be higher and might act defensively in protecting its cubs. Once again proceed with precaution as mentioned above, and although this should not have to be mentioned, do not attempt to lure or touch any cubs although they might be interested in you, the mother bear is likely to be aggressive towards you for this. " ]
            },
            {
                "subHeading": "Know proper plan of action in case of a bear attack " 

            },
            {
                "paragraph": "Bear spray is something you never wish you will use but can feel reassured you have! In the unlikely event of a bear encounter and seeing that the proper plan of action against a bear is not working, it might ultimately be the best solution to avoid a bear getting any closer. This takes extreme caution and will want to make sure that no one else is in the pathway of spraying and releasing the valve for bear spray." 
            },
            
            {
                "subHeading": "Know your food storage plan " 
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_1.jpg",
                "subtext": ""
            },
            {
                "paragraph": "The first thing to find out when planning to go camping is whether the campground offers food lockers or not. Usually most campgrounds in bear country will have food lockers but there are many others we have stayed at without food lockers. "
            },

            {
                "paragraph": "Food lockers are large, metal storage bins with a locking latch nearly impossible for a bear to open if they try. If you are in a campground with a food locker you can feel reassured you have a secure place to lock your food if a bear is drawn to your site. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_2.jpg",
                "subtext": ""
            },
            {
                "paragraph": "If there are no food lockers you will want to bring either a bear proof cooler or a bear canister, both designed to make it impossible for a bear to open as much as they try. The bear canister is a much more affordable route and a must for backpacking lightly and safely, but are usually a bit smaller than bear proof coolers. Bear proof coolers are more costly and heavier but allow for much more food storage space. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_3.jpg",
                "subtext": ""
            },
            {
                "paragraph": "Bears have become so accustomed to humans being around their homes that they have no problem opening car doors and getting into any food items you think you have “hidden” in there. Vehicles, in comparison to food lockers or bear canisters, are almost too easy for bears to get into. Vehicles are not scent proof and even though us humans can't smell something outside of a vehicle, a bear has a nose 100x more sensitive than humans and will clearly spot the vehicle with food supplies. All this leads to dangerous situations for both humans and for bears ultimately rather than taking proper precautions. "
            },
            {
                "paragraph": "In the case of a bear canister being used while backpacking or if no food lockers are available, the safest solution is securing a bear canister with rope at least 10 feet above ground level and tied to 2 trees. This elevation is recommended for proper clearance that a bear even on its hind legs will not be able to reach the food and will likely go on its way to find food elsewhere. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_4.jpg",
                "subtext": ""
            },
            {
                "subHeading": "Know your full list of items to bring and NOT bring"
            },

            {
                "paragraph": "You will want to double check your backpack before leaving home with some of these items as well as understanding what can deter a bear away. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_5.jpg",
                "subtext": ""
            },
            {
                "subHeading": "Attractions"
            },
            {
                "boldPar":"Mint scented items, food, or oils"
            },
            {
                "paragraph": "Bears love mint scented foods, items, and oils. Usually the most common mint scented item most people bring to campgrounds or out in the wilderness is toothpaste, it is recommended to safely store your toothpaste and toothbrushes in your food locker, bear locker, or bear canister. Remember to not bring your toothpaste into your tent as this scent might attract a bear over (better to have a bear come to your food locker rather than your tent). Avoid bringing mint scented oils or perfumes as well because this could give you a scent tracker to a bear. "
            },
            {
                "boldPar":"Lavender"
            },
            {
                "paragraph": "Although lavender is a lovely scent and a great insect repellent, bears love this scent as well and will be attracted to follow it or arrive at your campsite. It is recommended to avoid lavender scented perfumes, body sprays, oils, or hygiene products such as deodorant. Lavender scented beauty products should not stay in your tent and should be stored in a food locker or bear canister away from your tent. "
            },
            {
                "boldPar":"Sweet, sugary scents and foods "
            },
            {
                "paragraph": "Just like us humans love to munch down on sweet and yummy foods to satisfy our sweet tooths, guess what, bears love it just as much! Beware of sweets such as donuts, jam with toast, honey, and nutella - oh yeah the jokes about bears loving nutella are very true! It’s recommended to avoid leaving any scent trackers of these foods because bears will definitely be interested, if you must consume the entirety of your sweets while in camp or as your snack, but do not leave any extra remaining bits and pieces on the ground, because first, “leave no trace” should be the motto for everyone out and about in wilderness or campgrounds - but also because those bits and pieces could attract a bear to your campground (not to mention ants and rodents). Avoid ever bringing any sweet food, snacks, or drinks into your tent. It is recommended sweet foods be sealed (vacuum sealed if possible) properly and left in a food locker, bear canister, or placed safely away from your campsite overnight. "
            },
            {
                "boldPar":"Fresh meats or seafood "
            },
            {
                "paragraph": "As expected meats and seafood are going to bring a hefty smell to your site. It is recommended if possible to consume fresh meats and/or seafood on the first night and then you are left without worries. Although if you are planning to bring a hefty amount of meat to barbecue in a campground there are a few highly recommended ways to pack and store these items. "
            },
            {
                "boldPar":"Citrus scents or foods "
            },
            {
                "paragraph": "Bears love the smells of oranges, mandarines, nectarines - all the citrus and sweet flavors and smells they bring along! It is recommended to avoid wearing perfumes, body sprays, or citrus essential oils that could attract a bear’s curiosity to these scents. Avoid using citrus sprays, cleaners, or essential oils in a campsite or in wilderness because these are very attractive smells that will call a bear over involuntarily. Avoid having any citrus drinks or beauty products especially in your tent, make sure to store any citrus scented items in a food locker, bear canister, or place safely at least 20 feet away from your campsite if no other options are available. "
            },
            {
                "subHeading":"Deterrents"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/CBC_1/CBC_1_6.jpg",
                "subtext": ""
            },
            {
                "boldPar":"Pine scented cleaners and essential oils "
            },
            {
                "paragraph": "One of the most effective and safest deterrents you can bet on is using pine scented cleaners or essential oils to make a bear want to stay away. People who live in bear country and need to evade bears from getting into their trash cans will soak a rag or towel in pine scented cleaners or essential oils and it is a safe bet bears will move on their way and not mess with your house. It is recommended to gently spray some pine scented oils or cleaner spray over your food locker or bear canister especially if there is an abundance of smelly or fresh food. "
            },
            {
                "boldPar":"Bleach or ammonia scented cleaners"
            },
            {
                "paragraph": "The scent of bleach or ammonia can really deter a bear away from a property and is often used for homeowners in bear country trying to get bears away from their trash cans or yard area. A closed lid bucket can be filled with bleach or ammonia scented cleaner and left outside for any wandering bears to move on their way. One thing everyone should note about bleach and ammonia is to NEVER mix these two into one solution as these fumes could be lethal. Please be aware for the safety of everyone including wildlife that these cleaners should never be sprayed on anyone or any wildlife, it is simply meant as a scent deterrent for bears attracted to garbage cans or other food sources posted outside.  "
            },
            {
                "boldPar":"Talk show radio stations"
            },
            {
                "paragraph": "In comparison to music, the sound of people talking on a radio show has been tested to scare away a bear from a campsite or empty home, whereas just music playing in the background does nothing. People living in bear country have seen this phenomenon when they are out of town and leave a radio talk show playing in the background and once a bear comes around the premises they are more likely to scare away than if they hear music playing in the background. The radio talk shows scare bears away because they perceive it as activity inside the home, or in the area, rather than music seeming to not relate to human activity. This is a good idea for those camping out in the wilderness or at a campsite as well and want to leave some background noise on during the night or during a daytime exploration where you leave your site unattended. "
            },
            {
                "boldPar":"Loud noises such as pots & pans & yelling "
            },
            {
                "paragraph": "Loud noises do scare bears away such as hitting pots and pans together, and yelling when applicable if an encounter does happen to occur. People who live in bear country will do this if a bear is showing interest to search through the property. In any case, this is a useful tip to know whether you are staying in a cabin, at a campsite, or spot a bear from a distance - it will probably stray away from your loud noises. "
            },
            {
                "boldPar":"Cider vinegar  "
            },
            {
                "paragraph": "The strong, odorous scent of cider vinegar can make anyone including myself strike an abhorred look sideways in dismay! This is true for bears as well and can be used as a natural deterrent from a campsite, food locker, or garbage bins. Generally cider vinegar is used as another deterrent similar to the ammonia or bleach scented cleaners but this is a much safer deterrent for everyone - without the risk of dangerous skin contact and no potentially lethal fumes to be inhaled in comparison to the ammonia or bleach scented cleaners. Cider vinegar can be gently sprayed on food lockers, bear canisters, or on barbecue grills after use. This is often used by homeowners in bear country with recently used barbecue grills or garbage cans full of food. "
            },
            {
                "boldPar":"Large groups and music"
            },
            {
                "paragraph": "Bears try to avoid human interaction as much as possible and a way to help this is by playing music and not hesitating to be loud while out on the trail. Large groups are always recommended because this helps bears identify humans approaching and will move themselves out of human sight. If a large group is not possible then playing music can be effective in alerting a bear you are crossing through their area. Being quiet on the trail can surprise a bear and could cause defensive reactions. A good solution for this is having a bear bell attached to your backpack, the bell will resonate throughout the area and can safely alert a bear. "
            }
        ]
    },
    {
        name: 'camping-essentials-checklist',
        title: 'Camping Essentials Checklist: Your ready to-go packing guide ',
        main_image:"https://imagestravelblog.s3.us-east-2.amazonaws.com/CEC_2/CEC_2_MAIN.jpeg", 
        content: [`Welcome to your ultimate camping checklist! Whether you’re a first time camper or an experienced avid camper, this is the reliable checklist you need to ensure nothing important is left behind every time before heading out! We are excited to share our recommendations with the world and know it’ll be as useful as it has been for us in all our camping adventures! Feel free to check out our recommended shopping cart items all available on Amazon to make your shopping experience quick and easy! As Amazon Associates we earn from qualifying purchases. `],
        article_body: [
            {
                "moreLink": true,
                "link":"https://dosoutdoors.com/article/camping-in-bear-country",
                "description":"Camping in Bear Country; Your ultimate planning guide"
            },
            {
                "boldPar":"Campsite:"
            }, 
            {
                "ArticleTextListSquare": [
                    {"linkURL": "https://amzn.to/3uIQ6pK", "text":"Tent"}, 
                    "Sleeping pad or sleeping matress" ,
                    {"linkURL": "https://amzn.to/3tCeV5t", "text":"Sleeping bag"},
                    {"linkURL": "https://amzn.to/33x9zh7", "text":"Camping pillow"},
                    "Solar powered lantern or flashlight ",
                    {"linkURL": "https://amzn.to/3uCkCRT", "text":"Camping chairs "},
                    {"linkURL": "https://amzn.to/3vWQpxm", "text":"Fire start (if applicable) "},
                    "Firewood (if applicable) ",
                    "Cooler or bear canister (depending on campground conditions)",
                    {"linkURL": "https://amzn.to/3hfsK7h", "text":"Reusable ice packs for cooler"},
                    {"linkURL": "https://amzn.to/3uNVjg8", "text":"Hammock (optional) "},
                    {"linkURL": "https://amzn.to/3o5nBzQ", "text":"Extra blanket(s)"},
                    {"linkURL": "https://amzn.to/2SFdMNv", "text":"Portable power station"},
                     ]
            },
            {
                "SectionDivider":"normal-divider-spacing-below"
            }, 
            {
                "boldPar":"Personal Items:"
            },   
            {
                "ArticleTextListSquare": [
                    {"linkURL": "https://amzn.to/3y0BhAQ", "text":"Sunscreen"},
                    {"linkURL": "https://amzn.to/3o39hbk", "text":"Mosquito repellent"},
                    {"linkURL": "https://amzn.to/3bhvWeQ", "text":"Mosquito repellent candle"},
                    {"linkURL": "https://amzn.to/3hfwfdV", "text":"Headlamp (with red light)"},
                    {"linkURL": "https://amzn.to/3bhCZUM", "text":"Parachord (20 feet or longer)"},
                    "Waterproof Matches or lighter",
                    {"linkURL": "https://amzn.to/3bhVjNH", "text":"Duct tape"},
                    {"linkURL": "https://amzn.to/3xZJe9r", "text":"Bear Spray"},
                    {"linkURL": "https://amzn.to/3bgbhaU", "text":"Camping Knife"},
                    {"linkURL": "https://amzn.to/3tFi8Rx", "text":"Compass"},
                    {"linkURL": "https://amzn.to/3blo63F", "text":"Multi Tool"},
                    "Toothbrush & toothpaste",
                    {"linkURL": "https://amzn.to/3d0A0l3", "text":"First aid kit"},  
                    {"linkURL": "https://amzn.to/3bCii6j", "text":"Toilet paper"},
                    {"linkURL": "https://amzn.to/3wy7LSa", "text":"Multi-use soap"},
                    {"linkURL": "https://amzn.to/3uJta9Y", "text":"Hand sanitizer"},
                    {"linkURL": "https://amzn.to/2SJaaKx", "text":"Lip balm (preferably with sunscreen)"},
                    {"linkURL": "https://amzn.to/3w2o4pd", "text":"Quick dry towel (for showers/swimming)"},
                    "Spare batteries AA, AAA ",
                    {"linkURL": "https://amzn.to/3bn4Rqq", "text":"Bear bell (optional)"},
                    {"linkURL": "https://amzn.to/3uH7ET0", "text":"Battery charger (optional)"},
                    {"linkURL": "https://amzn.to/3vYEHlL", "text":"Music speaker"},
                    {"linkURL": "https://amzn.to/3yf2xvI", "text":"Binoculars (optional)"},
                    {"linkURL": "https://amzn.to/3wyyEp4", "text":"Flushable biodegradable wipes (optional)"}
                    
                    
                     ]
            },
            {
                "SectionDivider":"normal-divider-spacing-below"
            }, 
            {
                "boldPar": "Clothing: "
            },
            {
                "ArticleTextListSquare": [
                    {"linkURL": "https://amzn.to/33yqEqP", "text":"Moisture wicking long sleeve"},
                    "Moisture wicking t-shirts ",
                    "Moisture wicking pants/shorts ",
                    "Moisture wicking underwear ",
                    "Hiking socks", 
                    {"linkURL": "https://amzn.to/3wBwaGq", "text": "Rain jacket"},
                    "Insulated jacket ",
                    {"linkURL": "https://amzn.to/2NKFUN8", "text": "Hiking boots"},
                    "Sleepwear", 
                    {"linkURL": "https://amzn.to/3y4x8Mk", "text":"Foot warmers (for sleeping) "},
                    {"linkURL": "https://amzn.to/31Wao20", "text": "Bandana (against bugs/dirt) "},
                    "Bathing suit (optional) ",
                    {"linkURL": "https://amzn.to/3vWY8LQ", "text": "Campsite/shower sandals "},
                    "Gloves/mittens (optional)", 
                    "Warm hat/beanie (optional)", 
                    "Water shoes (optional) ",
                    "Sun hat (optional)" 

                ]
            },
            {
                "SectionDivider":"normal-divider-spacing-below"
            },
            {
                "boldPar": "Food & utensils: "
            },
            {
                "ArticleTextListSquare":[
                    {"linkURL": "https://amzn.to/3t9c1Fy", "text": "Portable mini stove"},
                    {"linkURL": "https://amzn.to/3tMKNVk", "text": "Fuel Cans"},
                    {"linkURL": "https://amzn.to/3vYokpl", "text": "Titanium cups"},
                    {"linkURL": "https://amzn.to/3hk8UHZ", "text": "Titanium cooking pot"},
                    {"linkURL": "https://amzn.to/3oah7Qp", "text": "Mini frying pan "},
                    {"linkURL": "https://amzn.to/3eDt2Dg", "text": "Mini foldable spatula"},
                    {"linkURL": "https://amzn.to/3fNwHz7", "text": "Titanium sporks"},
                    {"linkURL": "https://amzn.to/3f5HUJy", "text": "Reusable water bottles"},
                    {"linkURL": "https://amzn.to/2P0XrAO", "text": "Reusable dish scrapper "},
                    "Gallons of water", 
                    "Garbage bag(s)", 
                   " Grill brush (optional)", 
                   {"linkURL": "https://amzn.to/2SKIWDl", "text": "Aluminium foil (optional) "},
                   "Charcoal (optional) ",
                    "Charcoal (optional)", 
                    "Portable grill rack (optional) ",
                    "Marshmallow roasting forks (optional) "

                ]
            },
            {
                "SectionDivider":"normal-divider-spacing-below"
            },
            {
                "boldPar": "Suggested food items (optional): "
            },
            
            {
                "ArticleTextListSquare":[
                    {"linkURL": "https://amzn.to/3hlBBV4", "text": "Gallon(s) of water"},
                    {"linkURL": "https://amzn.to/3y63B4H", "text": "Soup cans (with push open lid) "},
                    {"linkURL": "https://amzn.to/3bkRNlw", "text": "Packaged Mac & Cheese"},
                    {"linkURL": "https://amzn.to/3f8EFBe", "text": "Packaged Rice or Quinoa"},
                    {"linkURL": "https://amzn.to/3oh0mDi", "text": "Packaged beans"},
                    {"linkURL": "https://amzn.to/3birnAI", "text": "Tortillas"},
                    {"linkURL": "https://amzn.to/33D31NS", "text": "Bread"},
                    {"linkURL": "https://amzn.to/2RMRglz", "text": "Peanut Butter"},
                    "Avocados",
                    {"linkURL": "https://amzn.to/3hkjqPC", "text": "Protein bars"},
                    {"linkURL": "https://amzn.to/3uHwJNF", "text": "Oatmeal"},
                    {"linkURL": "https://amzn.to/3fdO1vu", "text": "Bananas"},
                    "Dried fruit", 
                    {"linkURL": "https://amzn.to/2RMjlsW", "text": "Trail mix"},
                    {"linkURL": "https://amzn.to/2RJfYDo", "text": "Apple sauce"},
                    "Instant coffee ",
                    "Herbal tea, green tea, or black tea", 
                    "Beef jerky (plant based jerky available)", 
                    {"linkURL": "https://amzn.to/3xYzDjj", "text": "Parmesan cheese (or other aged/dry cheese) "},
                    "Graham crackers", 
                    "marshmallows", 
                    "chocolate (smores)" 

                ]
            },
            {
                "SectionDivider":"normal-divider-spacing-below"
            },
            {
                "paragraph": "Happy Adventuring! :) "
            }
            
        ]
    },
    {
        name: "recommended-backpacking-gear",
        title:"Recommended Backpacking Gear: Quality findings on a budget",
        main_image:"https://imagestravelblog.s3.us-east-2.amazonaws.com/RBG_3/RBG_3_MAIN.png",
        content: [`Explore our list of personally tested and recommended backpacking gear we have found to be the best finds for high quality and great value in one! Best of all, we have found these recommended products directly on Amazon ready for you to add to your cart and be on your way! As an Amazon Associate we  earn from qualifying purchases.`],
        article_body: [
            {
                "AAfiliateItem": true,
                "title":"Osprey Kyte 36 Women's Hiking Backpack",
                "url": "https://amzn.to/3tdCfqH",
                "imagehtml": '<a href="https://www.amazon.com/Osprey-Packs-Womens-Backpack-Green/dp/B079GLTFPR?pd_rd_w=y3B2A&pf_rd_p=bc5856f7-ca30-40b6-8d37-7814a2494e00&pf_rd_r=634BC9A19KSPSYHDC82R&pd_rd_r=9fb89605-1d0c-4f94-94a3-fcf5199e9763&pd_rd_wg=Ya3YD&pd_rd_i=B07MRL2Y5K&psc=1&linkCode=li3&tag=dosoutdoors-20&linkId=e338f471407ccb7e9b7aeb73bd4ea37b&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B079GLTFPR&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B079GLTFPR" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Available in a variety of colors and sizes, please refer to Osprey’s sizing charts for your optimal size. ",
                "recommend": " This is a 36 liter backpack with more than enough space for a week’s worth of space for food, tools, and personal items. For a reputable backpacking brand of high quality such as Osprey, this is a great price for a pack this size that will last you years and through dozens of trips. This has been our favorite backpack for women, for camping or even while backpacking through Europe!  ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Osprey Kestrel 48 Men's Backpacking Backpack",
                "url": "https://amzn.to/3g5oHK5",
                "imagehtml": '<a href="https://www.amazon.com/Osprey-Packs-Kestrel-Backpack-Medium/dp/B07GHWZ5QH?dchild=1&keywords=osprey+48+liter+backpacking&qid=1618287429&sr=8-2&linkCode=li3&tag=dosoutdoors-20&linkId=1d557e58baafe659b08949ca3ca3a5cf&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07GHWZ5QH&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07GHWZ5QH" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Available in a variety of colors and sizes, please refer to Osprey’s sizing charts for your optimal size.",
                "recommend": " This is a 48 liter backpack with more than enough space for a week’s worth of space for food, tools, and personal items. For a reputable backpacking brand of high quality such as Osprey, this is a great price for a pack this size that will last you years and through dozens of trips. This has been our favorite backpack for men, for camping or even while backpacking through Europe!",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"GSI Outdoors 74125 Compact Scraper , Grey, 3.4 inch",
                "url": "https://amzn.to/2P0XrAO",
                "imagehtml": '<a href="https://www.amazon.com/gp/product/B001F0MHE6?ie=UTF8&linkCode=li3&tag=dosoutdoors-20&linkId=203cd522f84ca0c6ea516e2d39aeb699&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B001F0MHE6&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B001F0MHE6" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " About the size of a large palm, ultra lightweight, and can be rewashed and reused every time like new! ",
                "recommend": " This will make your life so easy for scrubbing off any food residue left in the bottom of your cups and pots, and best of all, the rubber does not leave scratch marks. Lightweight, small, and good price worth its value. This has been our favorite, random, little find that has turned into a major game changer for cutting down time on cleaning up! ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"TheTentLab Deuce(R) Ultralight Backpacking Potty Trowel",
                "url": "https://amzn.to/38Nnjra",
                "imagehtml": '<a href="https://www.amazon.com/dp/B07KPQDDDY?th=1&psc=1&linkCode=li3&tag=dosoutdoors-20&linkId=14c94597a90cb2cbd58d2a011ba17479&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KPQDDDY&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07KPQDDDY" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Comes in a variety of colors to choose from.",
                "recommend": " Being in the wild anything to make the bathroom time even a tiny bit easier and comfortable is greatly appreciated, and this potty towel can make your bathroom preparation quicker, cleaner, and easier for you! Instead of needing to use your hands to dig a poop hole you can simply insert this at a 45 degree angle into the ground, easily scoop up some dirt, and once you're done and ready to cover your duty (doody) you can easily scoop and cover leaving no trace behind! Light as a feather, ultra thin, does not take up much space, and comes with an open insert to hang on a carabiner, key chain, or off your backpack.",
                "price": "5.00"
            },

            {
                "AAfiliateItem": true,
                "title":"Sawyer Products Premium Insect Repellent with 20% Picaridin, Lotion, 4-Ounce",
                "url": "https://amzn.to/3bW4ZOB",
                "imagehtml": '<a target="_blank"  href="https://www.amazon.com/gp/product/B00VV5KRD8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00VV5KRD8&linkCode=as2&tag=dosoutdoors-20&linkId=b4e12836d496e92a8f35dd74cc0509b0"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00VV5KRD8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=dosoutdoors-20" ></a>',
                "details": " Liquid in bottle does not leave white marks on your skin and goes on clear even with generous application. I myself have applied this only once in the mornings and I'm set for the whole day of protection!  ",
                "recommend": " In all our travels to the carribean, to the desert, and to the mountains through summertime and through bug season - this has been our best insect repellent solution even for the most pesky mosquitoes! This is non greasy, not thick, and not including the first couple of seconds out of the bottle - odorless while wearing! This came in a 2 pack and have taken this everywhere now, and has lasted us easily over a year and for over a dozen trips! ",
                "price": "8.95"
            },
            {
                "AAfiliateItem": true,
                "title":"TOAKS Titanium 450ml Cup",
                "url": "https://amzn.to/3cVWzGd",
                "imagehtml": '<a href="https://www.amazon.com/TOAKS-CUP-450-Titanium-450ml-Cup/dp/B008NOYQ6E?dchild=1&keywords=titanium+backpacking+cup&qid=1616473331&s=sporting-goods&sr=1-5&linkCode=li3&tag=dosoutdoors-20&linkId=4c635f5a7e456ca664dd918a144098e2&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B008NOYQ6E&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B008NOYQ6E" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " This has been used extensively under high heat and has not warped, melted, or discolored. ",
                "recommend": " This is a durable, multi-use cup you'll love to have gotten! We have found the material to be quality titanium, durable under high heat without warping, and very lightweight! The size of the cup has been a perfect fit for fitting half a bottle of water, a cooked meal, and can be carried through a carabiner on the outside of your backpack for extra packing convenience! ",
                "price": "19.95"
            },
            {
                "AAfiliateItem": true,
                "title":"Salomon Women's X Ultra 3 MID GTX W Hiking Boots",
                "url": "https://amzn.to/2NKFUN8",
                "imagehtml": '<a href="https://www.amazon.com/Salomon-Womens-Ultra-Hiking-Crown/dp/B072PPXZMM?dchild=1&keywords=salomon+boots+womens&qid=1616546063&s=sporting-goods&sr=1-4&linkCode=li3&tag=dosoutdoors-20&linkId=8abb42ffca18e3b7b7fb2823018d8be5&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B072PPXZMM&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B072PPXZMM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Don’t forget to accompany with hiking socks for a complete comfy experience on your feet! These are true to size, for further details see size chart. ",
                "recommend": " After many reviews and trials on dozens of hiking/backpacking boots, I found these boots to have the best qualities for long lasting enduring and bang for your buck. The grip is rated high and holds up against slippery slopes, mud, and gravel. They are waterproof tested - Yes I have taken them into the water, through creeks, rivers, and even part of a lake - and they perform amazing every time leaving me with clean, dry socks in the end always. Personally I have always had trouble finding good boots that will not give me blisters, especially after those hikes into a backpacking campground, and these have been my best ally for fighting against blisters! Find your personal optimal size and you will love taking these outdoors every time. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"MPOWERD Luci Original: Solar Inflatable Light",
                "url": "https://amzn.to/3cdJN6z",
                "imagehtml": '<a href="https://www.amazon.com/MPOWERD-Luci-Original-Inflatable-Features/dp/B074NMPPVK?crid=3R7P439CF0BHR&dchild=1&keywords=solar%2Bcollapsible%2Blantern&qid=1616553231&s=sporting-goods&sprefix=solar%2BColl%2Csporting%2C325&sr=1-23&th=1&linkCode=li3&tag=dosoutdoors-20&linkId=accb074393e1234be8502af61a781f5b&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B074NMPPVK&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B074NMPPVK" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Allow the top of the solar lantern to receive direct sunlight for at least 2 hours to ensure it will last as long as advertised. Good for placing on picnic tables, near tent, in tent, as ambient light for cooking, etc. ",
                "recommend": " This is one of our favorite solar lantern when backpacking! Superlight, solar powered, compressible, durable materials, multiple light settings (low, medium, high, flashing, and red light), and best of all this solar lantern lasts for over 8 hours while on! This solar lantern can even be hung from a desired high place to further illuminate the area during night sky, or hung on your backpack while hiking and charging at the same time! ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"PETZL, TACTIKKA Stealth Headlamp with 300 Lumens",
                "url": "https://amzn.to/2QtELdL",
                "imagehtml": '<a href="https://www.amazon.com/PETZL-TACTIKKA-Headlamp-lumens-Ultra-Compact/dp/B07RRR4BYP?dchild=1&keywords=backpacking+headlamp+red+light&qid=1616554989&s=sporting-goods&sr=1-61&linkCode=li3&tag=dosoutdoors-20&linkId=2a49b8cc9663aa982b6379556640a806&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07RRR4BYP&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07RRR4BYP" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " It is important to note these are battery powered and while the battery life is well over 24 hours of consistent use, it is important to remember to bring spare batteries in case these are left on by accident one time or in general to be safe and prepared. ",
                "recommend": " A headlamp is a must for either backpacking or camping! You will need a headlamp for either going to the restroom in the dark, getting into or out of your tent at night, and in general to see what is around you among the darkness. The best part of this headlamp is the multiple settings you can use this with (low, medium, high, and red lights) depending on the environment and how much light you need. Many other headlamps will not offer red light mode as an option and while these might have a much more enticing price you will want to make sure to have red light mode because red light is not detectable by night time insects such as mosquitos, moths, flies, etc. With these you can ensure all t",
                "price": "5.00"
            },
			{
                "AAfiliateItem": true,
                "title":"MSR IsoPro Fuel 8 oz.",
                "url": "https://amzn.to/3tMKNVk",
                "imagehtml": '<a href="https://www.amazon.com/MSR-IsoPro-Fuel-8-oz/dp/B00T3HECDM?crid=11DXFR0X639AP&dchild=1&keywords=msr+isopro+fuel&qid=1616556062&s=sporting-goods&sprefix=msr+iso%2Csporting%2C224&sr=1-2&linkCode=li3&tag=dosoutdoors-20&linkId=8bd9f05923ffc1cca539c61dd886fb04&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00T3HECDM&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B00T3HECDM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Make sure to pair this with your very own “AOTU Portable Camping Stoves Backpacking Stove with Piezo Ignition Stable Support Wind-Resistance Camp Stove for Outdoor Camping Hiking Cooking” to have a complete cooking set ready for anything and everything out in the wilderness!  ",
                "recommend": " These are our favorite fuel cans to depend on in the wilderness, once we found these we have never looked back! These come in small 8oz sizes so they are easy to package, super lightweight, and have excellent burning efficiency! As a vague reference, a small fuel can has provided us with enough fuel for two people, 3 nights, using this to heat up both water and foods during breakfast, lunch, and dinner. These are pressurized fuel cans to ensure the maximum efficiency and minimal consumption for your use. Tested against aggressive winds and below freezing temperatures (such as our winter time trip to Monument Valley!) ,this has withstood the environmental impacts and still performed flawlessly in heating up our water and food items. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Black Diamond Alpine Carbon Cork Trekking Poles",
                "url": "https://amzn.to/2PnAat0",
                "imagehtml": '<a href="https://www.amazon.com/Black-Diamond-Alpine-Carbon-Cork/dp/B07KXZ9JGZ?pd_rd_i=B07MJCN4M4&psc=1&linkCode=li3&tag=dosoutdoors-20&linkId=fb15ee1bc3ab6fee9cf5bf63f48dff3c&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07KXZ9JGZ&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07KXZ9JGZ" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Trekking poles come with multiple tip adapters to change according to the terrain you are on.",
                "recommend": " These trekking poles are an easy task to carry around because they’re super lightweight weighing under .7 pounds, compress to a mere 8” length when not in use, and have multiple adjustable heights depending on the height of the user. In comparison to other trekking poles these have withstood multiple types of terrain without damage, making their initial cost really worth your buck for the long haul! An important note about this design is the cork handle, which prevents blisters and sweaty palms, and trust me you will appreciate this out on the trail! ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Counter Assault - Maximum Strength & Distance Bear Repellent Spray",
                "url": "https://amzn.to/3cXxX13",
                "imagehtml": '<a href="https://www.amazon.com/Counter-Assault-CA-18H-SB-10-2-Ounce/dp/B001DQ76JI?ascsubtag=amzn1.osa.58d6f151-9dce-43fd-8c15-39ac19ffa4d9.ATVPDKIKX0DER.en_US&crid=3KJ4K0AJRHSQA&cv_ct_cx=bear+spray&cv_ct_id=amzn1.osa.58d6f151-9dce-43fd-8c15-39ac19ffa4d9.ATVPDKIKX0DER.en_US&cv_ct_pg=search&cv_ct_we=asin&cv_ct_wn=osp-single-source-earns-comm&dchild=1&keywords=bear+spray&pd_rd_i=B001DQ76JI&pd_rd_r=3e4bdd6a-8ff5-42a1-8cb3-0caa0389b6b6&pd_rd_w=WgYZ3&pd_rd_wg=Pr8KG&pf_rd_p=e666d5aa-04ca-46aa-86b0-07ac28e037d4&pf_rd_r=3K8ATK06T3EBPZE1FJJ7&qid=1617751617&sprefix=bear+spray%2Caps%2C255&sr=1-2-64f3a41a-73ca-403a-923c-8152c45485fe&linkCode=li3&tag=dosoutdoors-20&linkId=0d946784354f5b83d91b675173fee138&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B001DQ76JI&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B001DQ76JI" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Bear spray nozzle comes with a safety lid that should only be removed in case of use, also this is meant for one time use in a life threatening situation with an aggressive bear. ",
                "recommend": " This is an item that you hope to buy and never use, but nonetheless feels reassuring to be prepared with this bad boy! This is the ultimate, tested, reviewed and guaranteed to work bear spray in any unsafe encounter with a bear. As we mentioned, we have never had to use this but feel safe in the wilderness knowing that we have this with us. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Trekology Ultralight Inflatable Camping Travel Pillow - Compressible",
                "url": "https://amzn.to/3rTE36Q",
                "imagehtml": '<a href="https://www.amazon.com/Trekology-Ultralight-Inflatable-Camping-Travel/dp/B07MQJPVWD?dchild=1&keywords=backpacking+pillow&qid=1617752025&s=sporting-goods&sr=1-2-spons&psc=1&smid=A2ND75ZTUL6QAN&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUEyMFFTNjRZMlVTVUtHJmVuY3J5cHRlZElkPUEwNzE1MTI4M1JFMk5SS1cxWDJJWSZlbmNyeXB0ZWRBZElkPUEwMTg5NjU5MU0yVzhTRFE5MFRCVSZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU%3D&linkCode=li3&tag=dosoutdoors-20&linkId=403603108f7607b02f1d93f4edcb3e6f&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07MQJPVWD&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07MQJPVWD" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Pillow stays cool overnight for comfortable sleep. Comes in a variety of colors to choose from. ",
                "recommend": " This pillow is a pleasure to all because of how comfortable this is to sleep on! We both sleep like babies with these as a must on every trip! The amazing thing about this pillow is how lightweight it is while being versatile in its’ use as a head pillow or knee pillow, and all the while can be packed right back into its’ own personal pouch that can be hung on a carabiner outside your pack if you’d like. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"AOTU Portable Backpacking Stove with Piezo Ignition",
                "url": "https://amzn.to/3t9c1Fy",
                "imagehtml": '<a href="https://www.amazon.com/AOTU-Portable-Backpacking-Ignition-Wind-Resistance/dp/B07NJYV3NP?dchild=1&keywords=backpacking+stove&qid=1617752353&sr=8-3&linkCode=li3&tag=dosoutdoors-20&linkId=9db3aa0d47efa384e99f190b0b2f18ea&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07NJYV3NP&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07NJYV3NP" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Don’t forget to accompany this with the recommended fuel can to be ready for a whole lot of outdoor cooking! ",
                "recommend": " You will love cooking with this it’s our favorite find! This supports very small cooking cups/pots up to large 8” cups/pots and comes with stove top stands to ensure stability of your cooking pot. ",
                "price": "5.00"
            },            
            {
                "AAfiliateItem": true,
                "title":"Snow Peak Titanium Spork Ultralight",
                "url": "https://amzn.to/3fNwHz7",
                "imagehtml": '<a href="https://www.amazon.com/Snow-Peak-SCT-004-Titanium-Spork/dp/B000AR2N76?dchild=1&keywords=backpacking+spork&qid=1617752531&sr=8-5&linkCode=li3&tag=dosoutdoors-20&linkId=8786886c8e4a8b5b4ebeb4a027b2cd19&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B000AR2N76&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B000AR2N76" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Pair this with the light weight titanium cup for an optimal cooking and eating experience. Comes in a variety of colors to choose from. ",
                "recommend": " You can consider this your ultimate spoon and fork combined which is optimal for eating AND cooking! Easy to clean, non-stick material, super lightweight, and even comes with a carabiner to attach to the outside of your pack as an option. In the years of use this spork has proven itself durable and without warping, bending, or damage. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Dr. Bronner's - Pure-Castile Liquid Soap For Face, Body and Hair",
                "url": "https://amzn.to/3wy7LSa",
                "imagehtml": '<a href="https://www.amazon.com/Dr-Bronners-Unscented-Eucalyptus-Peppermint/dp/B06Y1M36YV?dchild=1&keywords=bronner+soap&qid=1617752794&sr=8-17&linkCode=li3&tag=dosoutdoors-20&linkId=17d268a9e55cdf256962bbb6ae0a1e8f&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B06Y1M36YV&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B06Y1M36YV" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Soap is heavily concentrated and a tiny dot of soap is enough to wash your hands for example, first drip a dot of soap into palms and scrub around palms and fingers, then use water and watch how much soap bubbles are created from this. One bottle will last a long time for an array of washings. ",
                "recommend": "This will easily become your new favorite soap, for traveling, hiking, backpacking, or to use while on the go for everyday chores! Made of all natural, organic ingredients, this is gentle on the skin yet tough on dirt and grease optimal for washing pots and pans, washing your hands, using as body wash, and can even be used as shampoo! This pack comes with an array of scents you can choose from for all types of tasks and washings. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"BearVault BV500 Bear Resistant Food Canister",
                "url": "https://amzn.to/3rVzpFe",
                "imagehtml": '<a href="https://www.amazon.com/Bearvault-BV500-Bear-Resistant-Container/dp/B004JVIT8Q?dchild=1&keywords=BearVault&qid=1622684442&sr=8-2&linkCode=li3&tag=dosoutdoors-20&linkId=6b6acf6ebbf04e310c43d3dd070fa741&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B004JVIT8Q&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B004JVIT8Q" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "You have two options for securing this away at night, you can carefully place bear canister at least 20 feet away from your campsite as recommended, or if you have a paracord you can wrap paracord around bear canister and hang from a secure tree branch, at least 10 feet above ground level as recommended. Please note that a bear canister should NEVER be inside your tent while sleeping and that while it is bear proof for opening, it is not scent proof. ",
                "recommend": " If there’s anything you ever needed more out in bear country for securing your food, it’s this bear canister! Backpacking in bear country involves meal planning to ensure you can securely store all your meals away from predators. If you plan to go for more than a one night backpacking trip, we recommend the larger sized bear canister as it will come in handy for comfortably securing all your food. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"RATSACK - Rodent Proof Food Storage Hanging Mesh Bag",
                "url": "https://amzn.to/3rRhVtP",
                "imagehtml": '<a href="https://www.amazon.com/RATSACK-Medium-8-oz-RS18/dp/B00HL2OA9M?dchild=1&keywords=ratsack+backpacking&qid=1617755570&sr=8-1&linkCode=li3&tag=dosoutdoors-20&linkId=b34c1aec59fcf644fd6983efb265f1b7&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00HL2OA9M&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B00HL2OA9M" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Your handy paracord will come in good use for hanging this sack up high from a tree. We chose the large size sack because it comfortably fits all our food for 3 night backpacking trips. ",
                "recommend": "This is the perfect food storage solution if you are backpacking to regions without bears but you may be dealing with rodents such as mice and squirrels. This is especially true in desert lands such as one of our trips to the Grand Canyon, where the mice and squirrels aggressively search through all bags for food findings. The wired mesh material is strong enough to resist rodent bites and will not break, best of all this sack is super lightweight, thin, and foldable when not in use. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"GEAR AID 550 Paracord and Carabiner",
                "url": "https://amzn.to/3dVeEVt",
                "imagehtml": '<a href="https://www.amazon.com/gp/product/B07JMBC37R?ie=UTF8&psc=1&linkCode=li3&tag=dosoutdoors-20&linkId=a86e5d14b01700fb996d6abfdc14515e&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07JMBC37R&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07JMBC37R" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Available in a variety of cord lengths. ",
                "recommend": "A reliable paracord has multiple uses and help it can provide out in the wilderness, from hanging a bear canister or mesh food sacks, to pitching a tent, putting up a sleeping hammock, to survival purposes! With all that said - a paracord is a must for safety! ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"BOGI Microfiber Travel Sports Towel - Dry Fast ",
                "url": "https://amzn.to/3fNXtaB",
                "imagehtml": '<a href="https://www.amazon.com/BOGI-Microfiber-Travel-Sports-Towel/dp/B078CQHYXM?dchild=1&keywords=backpacking+towel&qid=1617766977&s=sporting-goods&sr=1-10&linkCode=li3&tag=dosoutdoors-20&linkId=17510a8853db3bd6384064b8c27a7d9a&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B078CQHYXM&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B078CQHYXM" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Available in a variety of sizes and colors. ",
                "recommend": "This towel can serve multiple purposes out in the wilderness from drying up after a swim, to drying up from a workout, or to use as a cleaning towel if needed. This is not only good for camping but for everyday activities such as workouts, yoga, and the beach! This also makes a great gift for being such a handy, reusable, eco-friendly towel! This towel fits perfectly into a small bag that can be hung anywhere needed with a carabiner. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Columbia Women's Switchback Iii Adjustable Waterproof Rain Jacket",
                "url": "https://amzn.to/3wBwaGq",
                "imagehtml": '<a href="https://www.amazon.com/Columbia-Womens-Switchback-Adjustable-Waterproof/dp/B072JHJLLN?dchild=1&keywords=Columbia+rain+jacket+backpacking&qid=1617767319&s=sporting-goods&sr=1-4&linkCode=li3&tag=dosoutdoors-20&linkId=4019138d314032831c6ae8ea2a5c63f0&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B072JHJLLN&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B072JHJLLN" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Available in a variety of sizes and colors to choose from. ",
                "recommend": "These rain jackets have become our favorite coats to take for any backpacking trip from spring to autumn. These jackets are super lightweight, foldable, and will keep you dry from sudden rain or flash floods that may occur while out on the trail. The material is also great for cold you may experience with high winds, and retain body heat inside the jacket. These are also a great part of your outfit for everyday tasks or going out for walks! ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Columbia Men's EvaPOURation Jacket",
                "url": "https://amzn.to/3fNqzqF",
                "imagehtml": '<a href="https://www.amazon.com/Columbia-EvaPOURation-Jacket-Waterproof-Breathable/dp/B00DNNM3ME?dchild=1&keywords=Columbia+rain+jacket+backpacking&qid=1617767319&s=sporting-goods&sr=1-5&linkCode=li3&tag=dosoutdoors-20&linkId=e5c4487034f75be9d0cdaf6e85c135c1&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B00DNNM3ME&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B00DNNM3ME" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": " Available in a variety of sizes and colors to choose from. ",
                "recommend": "These rain jackets have become our favorite coats to take for any backpacking trip from spring to autumn. These jackets are super lightweight, foldable, and will keep you dry from sudden rain or flash floods that may occur while out on the trail. The material is also great for cold you may experience with high winds, and retain body heat inside the jacket. These are also a great part of your outfit for everyday tasks or going out for walks! ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Buff unisex Original Multifunctional Headwear and Face Mask",
                "url": "https://amzn.to/31Wao20",
                "imagehtml": '<a href="https://www.amazon.com/dp/B07BJ3N9MF?_encoding=UTF8&th=1&psc=1&linkCode=li3&tag=dosoutdoors-20&linkId=416cdae60ff95fe10d3ad697d0314907&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07BJ3N9MF&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07BJ3N9MF" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Available in a variety of colors and styles to choose from. ",
                "recommend": "You will love having nabbed this new buff because it will come in handy for multiple scenarios, and environments you may encounter. This is a comfortable headwear that can be worn in multiple ways, either as a headscarf, neck scarf, face mask. These will keep you cool while hiking out in warm weather and absorb sweat away from your eyes and mouth. This will be just as useful during colder seasons to help retain heat and keep your head warmer, and can comfortably be worn alone or under a beanie for keeping you warmer. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"WILD FINDING Waterproof Map Case Holder",
                "url": "https://amzn.to/39QYtXN",
                "imagehtml": '<a href="https://www.amazon.com/WILD-FINDING-Waterproof-Transparent-Electronics/dp/B0857GCSSW?dchild=1&keywords=backpacking+waterproof+sleeve&qid=1617769258&sr=8-13&linkCode=li3&tag=dosoutdoors-20&linkId=6eddae8095772df2a4cf29ad9c397d88&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0857GCSSW&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B0857GCSSW" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Available in a variety of sizes to choose from. ",
                "recommend": "If you are planning to go swimming or get yourself wet while not worrying about leaving valuables behind you might want to check this out! You will not have to worry about getting your pack wet and ruining important documents, maps, or electronics as long as they are safely stored in this waterproof holder. ",
                "price": "5.00"
            },         
            {
                "AAfiliateItem": true,
                "title":"CaliCase Extra Large Waterproof Floating Case",
                "url": "https://amzn.to/3fP2dg8",
                "imagehtml": '<a href="https://www.amazon.com/dp/B01M0Q7LHA?psc=1&pd_rd_i=B01M0Q7LHA&pd_rd_w=nMyzc&pf_rd_p=cbc856ed-1371-4f23-b89d-d3fb30edf66d&pd_rd_wg=8FZR4&pf_rd_r=M4VK8NAHZE3J3AJ1DAEY&pd_rd_r=1e852dfe-6740-44d6-b40f-d0dcc6b5a5e7&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUFJVzRMNEZXTlI2U1kmZW5jcnlwdGVkSWQ9QTAxODEzMDZWVEQ5SlRGNkNLNVMmZW5jcnlwdGVkQWRJZD1BMDAyMjA3ODJOMzhLQktESkhQRTUmd2lkZ2V0TmFtZT1zcF9kZXRhaWxfdGhlbWF0aWMmYWN0aW9uPWNsaWNrUmVkaXJlY3QmZG9Ob3RMb2dDbGljaz10cnVl&linkCode=li3&tag=dosoutdoors-20&linkId=23c1a9e83126d02daec4971e97ccdcd7&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B01M0Q7LHA&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B01M0Q7LHA" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Fits largest size of all cellphones with extra space on the sides and top.",
                "recommend": "This can be a relief to have especially if you want to take alot of pictures while swimming, near a body of water, or just to keep your phone safe and handy at any moment while staying nice and clean! We used these on our backpacking trip to Havasupai while swimming in the waters and were able to easily take pictures with our phones in these floating cases, all the while without worries of phone damage. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Adventure Medical Kits Watertight .5 Medical First Aid Kit",
                "url": "https://amzn.to/3d0A0l3",
                "imagehtml": '<a href="https://www.amazon.com/Adventure-Medical-Kits-Ultralight-Watertight/dp/B0009RIQBE?dchild=1&keywords=backpacking+first+aid+kit&qid=1617769885&sr=8-7&linkCode=li3&tag=dosoutdoors-20&linkId=dd07f574fd5beadc7ee0e65898d12244&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B0009RIQBE&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B0009RIQBE" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "Available in multiple medical kit sizes to choose from. ",
                "recommend": "This medical kit contains a variety of items you can be relieved to know you are prepared for, although hoping you will never need to use these. This kit is lightweight and contains soo many useful first aid kits in case of an injury, animal bite, or just to feel better from a headache or pain you may experience while outdoors and fatigued. We have always felt such a peace of mind to know we have this medical kit when we are outdoors and prepared in case of anything. This case is also waterproof so you can be sure all your items will stay just as new and reliable even with water exposure. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"Combat Wipes ACTIVE Outdoor Wet Wipes",
                "url": "https://amzn.to/3wyyEp4",
                "imagehtml": '<a href="https://www.amazon.com/Combat-Wipes-Ultralight-Biodegradable-Backpacking/dp/B074DDCW8B?dchild=1&keywords=backpacking+wet+wipes&qid=1617770238&sr=8-6&linkCode=li3&tag=dosoutdoors-20&linkId=233a14f69a9984283f28901f90f7f4a5&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B074DDCW8B&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B074DDCW8B" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "These can be packed in a ziploc or reusable sealable bag and taken in your pack to maintain freshness.",
                "recommend": "Wet wipes can be such a relief to have in the outdoors for cleaning your hands, wiping face of sweat and dirt, and cleaning up after yourself to maintain freshness and comfort. These wipes are made of all natural ingredients, biodegradable, and contain vitamin E. These will feel soo good to wipe yourself down after a long day on the trail full of sweat, dirt, and fatigue. ",
                "price": "5.00"
            },
            {
                "AAfiliateItem": true,
                "title":"RediTape 10934 Travel | Pocket Size, 3-Pack, Black",
                "url": "https://amzn.to/3cTk6Zo",
                "imagehtml": '<a href="https://www.amazon.com/RediTape-10932-Travel-Orange-Silver/dp/B079MX42T1?dchild=1&keywords=duct%2Btape%2Bbackpacking&qid=1617770506&sr=8-3&th=1&linkCode=li3&tag=dosoutdoors-20&linkId=ecdf4bacf72e9ed59c09e3217326c289&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B079MX42T1&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B079MX42T1" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "If you would like to take less duct tape for a short trip, we recommend bringing a pen and wrapping your desired amount of duct tape around the pen, this will allow you to be prepared while saving on weight and space in your pack. ",
                "recommend": "Duct tape can literally be a life saver! This is a pocket size, lightweight duct tape roll to take outdoors and be prepared for anything you may encounter, from a sleeping pad puncture, to a hole in your tent or backpack, to a critical life safety craft you may need to put together - duct tape is always reliable to bring with you! ",
                "price": "5.00"
            },  
            {
                "paragraph": "We hope all these recommended gear selections make your shopping experience a breeze! Feel free to check out the linked products and add them to your cart to be on your way for the outdoors! For more information about backpacking check out our other recommended articles such as “Backpacking checklist” and “Camping in bear country”. "
            },
            {
                "SectionDivider":"normal-divider-spacing-below"
            }
        ]
    },
    {
        name: '25-things-you-must-know-italy',
        title: '25 Things you must know before visiting Italy: Your Ultimate Italian Guide ',
        main_image:"https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_MAIN.jpeg",
        content: [`You will greatly appreciate knowing about these unexpected must-knows before reaching Italy, we have learned these in our numerous trips to different regions of Italy.`],
        "article_body": [
            {
                "subHeading": "Luxury cars require a minimum age of 30 to rent " 
            },
            {
                "paragraph": "If you are planning on renting a car to explore Italy, make sure any luxury cars are booked to persons over 30 years old, as this is the legal minimum age to rent a luxury car in Italy. Yes - this is a bit different than the United States, as the minimum age is 25, but be prepared for alternative options if the person booking and driving the rental car is under 30. Luxury vehicles consist of brands such as Land Rover, BMW, Mercedes, Audi, etc. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_1.jpeg",
                "subtext": "Driving through scenic Tuscany countryside and arriving to beautiful Sienna just before sunset"
                
            },
            {
                "subHeading": "Highways have toll booths " 
            },
            {
                "paragraph": "If you are planning to rent a car and drive away from the metropolitan cities to the countryside by highway please expect to pay with cash at toll booths. Highways will usually have toll booths every 20-50 miles. Depending on the region you are in the prices may fluctuate. On average expect to pay around 10 Euros at each toll booth, multiplied by however many toll booths you pass through. Toll booth costs can quickly add up if traveling hours away, prices may range between 6 and 15 Euros. Many of these toll booths will not accept credit cards, we’ve actually never seen any toll booths accepting credit cards while driving around different regions of Italy so be ready with cash.                "
            },
            {
                "subHeading": "Highways all over Italy have cameras for speeding" 
            },
            {
                "paragraph": " Highways in Italy will clearly display speed limits in kilometers per hour all throughout the country, and although you may not see any police cars enforcing the speed limit and may feel at ease to speed beyond the displayed limit - expect at least one mailed in ticket, or multiple tickets, with a clear picture of you driving and a timestamp of your infraction. If you are renting a car expect these tickets to arrive about 9 months later following your infracture, so our best advice is to keep to the speed limit. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_2.jpeg",
                "subtext": "Driving in northern Italy countryside with clearly marked signs "
                
            },
            
            {
                "subHeading": "GPS signal might not exist in countryside Italy, download offline map" 
            },
            {
                "paragraph": " Make sure to download offline maps of regions you will be visiting throughout Italy because you may not get the best signal once you are away from a large city. We like to use google offline maps but there are other platforms and applications you may use for navigation. "
            },
            {
                "subHeading": "Cash is a must" 
            },
            {
                "paragraph": " As nice as newer platforms are for ridesharing, these may not be available in major cities around Italy as they might have their own ridesharing requirements, allowing only taxi cabs in some major cities. Taxi cabs may not allow credit card payments and cash will be needed, plus they might not have exact change so be sure to have smaller denomination bills to pay your cab fare. If you are planning to rent a car and drive through long stretches of highways, expect toll booths requiring cash payment. Cash is extremely helpful for everything else because some smaller businesses or vendors will not accept credit card payments and many times you must purchase a minimum amount to pay with a card, if available at all.  "
            },
            
            {
                "subHeading": "Bring lower denomination euro bills" 
            },
            {
                "paragraph": "Make sure to break your large bills down into smaller bills, especially 1’s, 5’s, and 10’s because if not you may be forced to pay a higher amount when sellers or taxi cab drivers do not have spare change. If you are planning to buy anything from a small business, vendor, or hitch a taxi cab ride, you will need cash, and don’t be surprised to hear vendors and taxi cab drivers tell you they do not have change."
            },
            
            {
                "subHeading": "Restaurant siestas do exist" 
            },
            {
                "paragraph": "Don’t be shocked to see restaurants opening for dinner between 7 and 8 pm, this is part of siestas that exist all throughout Italy as a time to relax and unwind between lunch and evening dinner times. Be prepared to wait for full service meals and for dinner entrees,  these may take a while to be served especially if you are arriving when a restaurant is opening. If you are near a  tourist trap location, you may find food places open the whole day at the cost of quality and authenticity. The food culture in Italy is best appreciated as an experience, bringing us to our next point below!"
            },
            {
                "subHeading": "It is common for smaller restaurants to expect you to find your desired table yourself rather than waiting to be seated" 
            },
            {
                "paragraph": "If you are coming from the United States you might be accustomed to entering a restaurant and waiting for a waiter to lead you to your table - that’s not necessarily true in Italy. Many restaurants prefer that you enter, pick your table, and await a waiter to take your orders. Feel out a restaurant, see if people are waiting for a table, if there are open tables and there is no line you may just be able to walk in, sit down, and wait for a waiter to assist you accordingly. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_3.jpeg",
                "subtext": "We picked our table for an evening dinner in countryside Tuscany at La Porta del Val d’Orcia"
                
            },
            {
                "subHeading": "Restaurant menus are designed for a 3 course meal experience" 
            },
            {
                "paragraph": "The food culture of Italy values evening dinners as an experience to discover foods, drink wine, and unwind. Restaurant menus will usually show these 3 main courses: antipasti, primi piatti, secondi piatti."
            },

            {
                "paragraph": "In contrast, the United States runs on a faster paced culture, where food is seen simply as fuel to keep going, but when in Italy - take the time to discover and enjoy new foods, and expect to make it a 2-3 hours experience. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_4.jpeg",
                "subtext": "Our aperitif selection: burrata with roasted eggplant, zucchini, and tomatoes"
                
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_5.jpeg",
                "subtext": "Our primi piatti selection: handmade gnocchi topped with grated parmesan cheese"
                
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_6.jpeg",
                "subtext": "Our dessert option: handmade cannoli with raspberry cream"
                
            },
            {
                "subHeading": "Tipping is not common, it’s actually very rare" 
            },
            {
                "paragraph": "Restaurant tipping in Italy is not common, and many restaurants will not even have a receipt line for adding tips because this is not a custom. Restaurants in the United States have made tipping almost a requirement in order to pay waiters minimum wage, but this is not the case throughout Italy. Please keep this in mind when going to restaurants, because even if a tip is given, the tip may not even go to your waiter. "
            },
            {
                "subHeading": "Hot chocolate is literally melted, warm chocolate" 
            },
            {
                "paragraph": "We discovered this while enjoying the Christmas holidays in northern Italy. We were looking to warm up from the cold nights in the Alps so we ventured out for hot chocolate and came to discover that Italian hot chocolate is a creamy, thick chocolate ganache unlike we had known in the United States. Although you may find hot chocolate with an abundance of milk in more tourist trap locations, this is not the norm. Albeit, this is something delicious to try! Give yourself a new and delicious meaning to the concept of hot chocolate!"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_7.jpeg",
                "subtext": "Warming up with some hot chocolate (“cioccolata calda”) from the farmers market in Como"
                
            },
            {
                "subHeading": "Coffee drink names are unique to Italian language" 
            },
            {
                "paragraph": "Coffee is a great part of Italian culture and the most popular form of coffee is espresso. Whether you’re going to a cafe or a restaurant, coffee is consumed for breakfast, with pastries, during mid day at cafes, and after meals. Beware of how you order coffee drinks as they will not be interpreted in the same way, a cup of coffee will translate to an espresso, while a latte will interpret simply as milk. "
            },
            {
                "subHeading": "Athleisure is a big no-no" 
            },
            {
                "paragraph": "Fashion in Italy is very apparent as soon as you land, boasting with peacoats, boots, button ups, scarves, and business attire as everyday attire; therefore make sure to pack accordingly and blend in respectively. Athlesuire is not seen as appropriate attire when going out and about for errands, your business casual attire is considered a norm and a basic standard throughout Italy, therefore plan your outfits accordingly. Running sneakers are not something to wear out with casual outfits so expect to stick out like a sore thumb if you wear running sneakers. Better yet, find yourself some fashionable yet comfortable boots or dress up shoes and you’ll be one step closer to adopting the Italian fashion as your own."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_8.jpeg",
                "subtext": "Window shopping in Como for men’s fashion"
                
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_9.jpeg",
                "subtext": "Window shopping in Como for women’s hats and accessories"
                
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_10.jpeg",
                "subtext": "Window shopping in Milan for men’s ties and business casual attire"
                
            },
            {
                "subHeading": "It’s very important to wear comfortable shoes" 
            },
            {
                "paragraph": "As a couple who loves to dress fashionably we understand shoes are an important part of any attire, but trust us on this one - prioritize comfort over fashion! Many large Italian cities such as Rome, Florence, etc, have streets lined with cobblestones as live remnants from the Roman empire. These stones are HARD, uneven surfaces that will hurt your feet after walking a full day. High heels or sandals will leave your feet sore and in a sour mood while exploring. Find yourself some fashionable shoes that can comfortably support walking thousands of steps over cobblestone streets all day long. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_11.jpeg",
                "subtext": "Cobblestone streets pave the way all throughout Florence"
                
            },
            {
                "subHeading": "Dress appropriately for museums and churches" 
            },
            {
                "paragraph": "Italy delivers! Abundant with beautiful, historic museums and churches that you will want to explore such as Santa Maria delle Grazie, St. Peter’s basilica at the Vatican, Duomo di Milano... the list goes on, but when planning to visit these sites remember to dress appropriately as you may be turned down at the entrance. This is especially true when visiting the Vatican as the visitor guide clearly states that short skirts, tank tops, and shoulder baring clothing may impede entry. Although this may be controversial it can be interpreted as a sign of respect. Be sure to keep attire in mind to be on your way to see beautiful, historic pieces."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_12.jpeg",
                "subtext": "St. Peter’s Basilica and Vatican tour line "
                
            },
            {
                "subHeading": "Book your train tickets ahead of time" 
            },
            {
                "paragraph": "Trains can provide all the transportation you need to travel between major cities in Italy, as a matter of fact these trains can safely take you all over Europe with ease. High-speed trains can transport you from Milan to Paris in a matter of 5 hours while sitting in comfortable seats and taking in beautiful landscapes! There are quite a few major train lines in Italy, the largest train line being ItaliaRail. This train line can transport you to hundreds of cities throughout the country and outside of the country. If you have a travel itinerary you will want to book your tickets at least a few months ahead of time as these trains are very popular for both locals and tourists. When onboard, make sure to have your ticket handy as staff will come by requesting to see proper  ticket purchases for everyone. This is a highly recommended form of transportation as you will be enjoying an abundance of beautiful sights! "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_13.jpeg",
                "subtext": "Boarding our train ride from Rome to Florence with ItaliaRail"
                
            },
            {
                "subHeading": "Book sightseeing bus tours for transportation" 
            },
            {
                "paragraph": "If you are visiting a major city and would like to explore multiple tourist attraction sites within the city, we suggest booking a tour bus company so you can hop on and off to your desired tourist attraction sites. These sightseeing bus tours can be booked for the exact number of days needed and operate all day into night time, stopping at major city attractions so you can just hop on and off as needed. Our personal favorite sightseeing bus tour was BigBus, which had stops at major attractions in Rome such as the Colosseum, Pantheon, Palatine hill, Spanish steps, etc."
            },
            {
                "subHeading": "Do not pay attention to street vendors" 
            },
            {
                "paragraph": "Street vendors roam streets of all major cities in Italy and if you pay ANY attention to them they will be very aggressive in forcing you to buy their offered knick knacks, so our best recommendation is to not pay attention to vendors at all. Many times you will see street vendors charismatically handing a “free flower” or “free balloon” but please beware these are never free - they will haggle you to pay for these items as soon as you grab them in your hand."
            },
            {
                "subHeading": "European toilets" 
            },
            {
                "paragraph": "Toilets in Europe are not the same as American toilets due to a few major differences. Toilets in Europe will normally have a small amount of water in the toilet bowl. A European toilet bowl with the amount of water seen in American toilets is most likely clogged. Toilet seat covers are nonexistent in Europe, so be ready to make your own seat cover every time. "
            },
            {
                "subHeading": "Historical buildings have different style of locks" 
            },
            {
                "paragraph": "Historical buildings in Italy, needless to say, are very old - and designed with different kinds of doors and locks that you may not be used to opening and closing. In our first adventure to Italy we encountered this problem, where we were not able to open our door once being out and about. We needed help from the host to open the door correctly - yikes! Please make sure to confirm with your host on how to open and close your doors if you are staying in a historical building. Historical buildings will usually not have the same type of modern lock and key configuration, because the design of these doors is based on deadbolts."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_14.jpeg",
                "subtext": "Pathway between historical building apartments in Varenna provides beautiful view of Lake Como"
                
            },
            {
                "subHeading": "Outlet adapters are needed for charging electronic devices" 
            },
            {
                "paragraph": "If you are bringing electronic devices that require a charge, which let’s be honest, who doesn’t need to charge some sort of electronic device nowadays - you will need an outlet adapter to convert your north american 120V plug to the european 110V plug. You will see all electrical plugs throughout Europe look different and thus an adapter will be needed to plug into these outlets. We recommend purchasing this highly rated European Plug Adapter from Amazon, that way you are prepared when you arrive and you pay a decent price, simply add it to your cart and now you are ready for Italy! "
            },
            {
                "AAfiliateItem": true,
                "title":"European charging adaptors in a 2 pack assembly",
                "url": "https://amzn.to/3glG2y2",
                "imagehtml": '<a href="https://www.amazon.com/European-Adapter-TESSAN-International-Adaptor/dp/B07L87GPPT?dchild=1&keywords=outlet+adapter+europe&qid=1623710817&refinements=p_72%3A1248909011%2Cp_76%3A1249152011%2Cp_n_condition-type%3A6358196011&rnid=6358194011&rps=1&s=hi&sr=1-1&linkCode=li3&tag=dosoutdoors-20&linkId=a59055e6a130acf91963dfcfd7f09818&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07L87GPPT&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07L87GPPT" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />',
                "details": "  ",
                "recommend": "  ",
                "price": "",
                "type":2,
                "subtext": "European charging adaptors in a 2 pack assembly with 3-prong outlet and duo usb outlets available. As an Amazon associate we earn from qualifying purchases"
            },
            {
                "subHeading": "Beware of pickpockets in major attraction sites" 
            },
            {
                "paragraph": "Major city attraction sites require being more aware of pickpockets and keeping your wallet and valuables stored securely and close to you. Public transportation near tourist attraction sites usually sees the highest rates of stolen wallets, purses, or phones from back pockets and jacket pockets or backpacks - so please remember to be aware and you should be okay. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/TYMKBVI_4/TYMKBVI_4_15.jpeg",
                "subtext": "Keep valuables secured in major attraction sites such as the duomo in Milan"
                
            },
            {
                "subHeading": "Learning a few Italian phrases will go a long way!" 
            },
            {
                "paragraph": "Learn a few important phrases you will most likely need to know and ask while in Italy such as, “thank you”, “yes please”, “the check please”, and “where is the bathroom?”. While not knowing fluent Italian should be okay especially in major cities, learning at least the most important or common phrases you will encounter will go a long way and will be greatly appreciated by Italians everywhere as a sign of respect for visiting and caring enough to learn how to communicate for the essentials. We highly recommend Paul Noble’s beginner Italian audio course, available on Audible along with an accompanying PDF of the most important phrases and translations you will need to know. In one month of consistent practice you will know how to communicate basic needs and questions, and best of all feeling proud of all you have learned!"
            },
            {
                "AAfiliateItem": true,
                "title":"Paul Noble’s beginner Italian audio course",
                "url": "https://amzn.to/3gtEVwu",
                "imagehtml": '<a href="https://www.amazon.com/Learn-Italian-Paul-Noble-Beginners/dp/B07W7LTXYL?dchild=1&keywords=Paul+noble+italian&qid=1623825452&sr=8-2&linkCode=li3&tag=dosoutdoors-20&linkId=5f5601e059b53eb31eac621ca6855d0e&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B07W7LTXYL&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dosoutdoors-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dosoutdoors-20&language=en_US&l=li3&o=1&a=B07W7LTXYL" width="1" height="1" border="0" alt="" style="border:none !important; margin:0px !important;" />                ',
                "details": "  ",
                "recommend": "  ",
                "price": "",
                "type":2,
                "subtext": "This great resource teaches in a conversational manner, you will be speaking your first Italian words in no time! As an Amazon associate we earn from qualifying purchases"

            },
            {
                "paragraph": "We hope these important must-knows help to make your next visit to Italy an amazing and comfortable experience. For more information view our other Italy articles for inspiration, guides, and recommendations! "
            },
            
            
            
            
            
            
           
            
            
            
            

        ]
    },{
        name: '11-free-budget-friendly-experiences-youll-love-in-cancun',
        title: '11 Free and Budget friendly experiences you’ll love in Cancun',
        main_image:"https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_MAIN.jpg",
        content:["If you’re anything like us, you love to find fun and memorable experiences you can enjoy all while not breaking the bank! These are some of our highly recommended budget friendly experiences to not miss out on while visiting Cancun! Visit our other Yucatan Peninsula articles for further inspiration, ideas, and know how’s before making your trip! "],
        "article_body": [
            {
                "subHeading": "Soak up the sun in the vast array of beautiful beaches!" 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Cancun is Famous for its beautiful, soft, white, sandy beaches - all beaches in Mexico are free and open to the public to enjoy from! Although it might seem you need to book an expensive, beachfront resort in order to enjoy the beach vibes, there are dozens of beaches that are open to the public throughout Cancun. Some beaches may be more crowded than other lesser known, more “secret” beaches but one thing's for sure - these are all free and for you to enjoy! Beaches are a must to enjoy in Cancun, and there are so many beaches to choose from that this could be a trip in itself. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_1.jpg",
                "subtext": "Enjoy from the dozens of white sandy beaches Cancun has to offer"
                
            },
            {
                "paragraph": "Pack your bathing suits because you will want to jump into the water every time! If there's one thing that you count on in Cancun, it’s the warm Carribean waters that no one can resist enjoying. You may be able to buy a snorkeling gear and view the array of exotic, Carribean fishe that inhabit these beautiful waters. "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_2.jpg",
                "subtext": "Beach hop around Cancun and make it an experience of its’ own"
                
            },
            {
                "paragraph": "Some of the most popular and conveniently located beaches for tourists include Playa Langosta, Playa Tortugas, and Playa Chac Mool. As a bonus, you could check out a beach club here and relax while sipping on cocktails and being catered to."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_3.jpg",
                "subtext": "Enjoy from an array of beach clubs with delicious food and drinks for a full day of relaxation and swimming"
                
            },
            {
                "subHeading": "Explore all Zona hotelera has to offer " 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Zona hotelera is quite a unique place you’ll love to either stay in or just visit and explore the vast array of resorts, malls, restaurants, water adventures, bars, and beaches located here."
            },
            {
                "paragraph": "Zona Hotelera (translates to “Hotel Zone”, known as the tourist zone) extends along a  14 mile (22.5km) strip of land wedged between the Cancun lagoon and the Carribean sea. This is a beautiful area to explore by foot and car, it is an experience of its own as you discover new places and experiences you might want to do here!"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_4.jpg",
                "subtext": "Hotel Zone has over 100 hotels and all-inclusive resorts among beautiful scenery and beaches"
                
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_5.jpg",
                "subtext": "Sunset looks amazing through our resort’s balcony"
                
            },
            {
                "subHeading": "Take a picture with the Cancun sign" 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Mark your official arrival to Cancun with this iconic, instagrammy picture of the colorful sign of “Cancun”. This is located in Zona Hotelera in Playa Langosta, a great excuse to plan a picture and a beach visit here! "
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_6.jpg",
                "subtext": "There are other unofficial signs in Cancun such as this one"
                
            },
            {
                "subHeading": "Window shop at these beautiful, outdoor malls " 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Cancun is full of new, beautiful malls, ranging from casual to luxurious brands from Mexico, United States, and Europe. The malls in Cancun offer day-long, fun experiences with hundreds of shops, restaurants, movie theaters, and other forms of entertainment. It is an experience of its own to walk around, window shop, pause for some tasty food, check out live entertainment, and enjoy fancy movie theaters."
            },
            {
                "paragraph": "We recommend checking out shopping malls such as Puerto Cancun, Plaza Americas, and La Isla."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_7.jpg",
                "subtext": "Zona hotelera  has a dozen small to large malls and shopping plazas to explore, browse, and shop"
                
            },
            {
                "subHeading": "Visit El Rey Archaeological zone in Zona Hotelera " 
            },
            {
                "paragraph": "Price: $3 USD"
            },
            {
                "paragraph": "Come and imagine yourself back in Mayan times by visiting these Mayan ruins dating back approximately 800 years. Located in the southern end of Zona hotelera, you can view over 40 stone structures still in place, including a temple dedicated to an important Mayan figure. With just a $3 entrance fee you can explore these ruins. This is open to the public everyday from 8am-5pm."
            },
            {
                "subHeading": "Visit El Meco Archaeological zone in Zona Hotelera " 
            },
            {
                "paragraph": "Price: $3-$4 USD"
            },
            {
                "paragraph": "Visit one of Cancun’s tallest pyramids at this archaeological zone located about 8km away from the center of Cancun. These ruins date back around 800 years and feature El Castillo (a pyramid,  at the center of the archeological site). This is one of the best preserved pyramids remaining in Cancun. For an entrance fee of only $4 USD you can explore daily from 8am-5pm. "
            },
            {
                "subHeading": "Visit Parque de Palapas in the center of Cancun" 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "If you would like a change of scenery away from the hotel zone (tourist zone) and are up for more of a locals’ experience, head over to the city center of Cancun between afternoon and evening and enjoy this locals’ hotspot for food vendors and live entertainment. If you are up for more of a cultural Cancun experience this is it! El Parque de las Palapas offers street vendors, food vendors, and live entertainment with zero entry fee - simply arrive, walk around, take in the sights and entertainment, and if your heart (more like your stomach) wishes, you can enjoy food, churros, and aguas frescas (refreshing fruit juices)! You can enjoy this everyday and year round."
            },
            {
                "subHeading": "Check out Mercado 28 in the center of Cancun" 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Visit Mercado 28 for quite an interesting experience to mingle with the local culture, people,  and scenery, and best of all you can find great deals on goods and souvenirs! "
            },
            {
                "subHeading": "Visit Kabah Park in the center of Cancun" 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Enjoy Kabah’s natural protected forest area full of ecological wildlife diversity, including wild birds, spider monkeys, and lizards. This is a beautiful place to comfortably enjoy walking around or have a picnic in the many picnic tables available for the public. This is a free entry and is enjoyed by many morning joggers."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_8.jpg",
                "subtext": "Take a stroll through Kabah Park and enjoy its’ beautiful ecosystem all for free"
                
            },
            {
                "subHeading": "Check out art murals in downtown Cancun" 
            },
            {
                "paragraph": "Price: Free"
            },
            {
                "paragraph": "Enjoy art murals paying homage to Cancun’s Mayan ancestors and heritage as you stroll through downtown Cancun. Downtown Cancun has a lot of movement and activities, hey while you’re near there make sure to check out Mercado 28 and Kabah Park as well! It wouldn't hurt to snap a picture of these murals and tag the artists as appreciation for the hard work and passion they have put into these artworks."
            },
            {
                "subHeading": "Take a Ferry ride to Isla Mujeres" 
            },
            {
                "paragraph": "Price: $15-30 USD"
            },
            {
                "paragraph": "Trust us when we say, a trip to Cancun is not complete without taking in the beauty of Isla Mujeres and all it has to offer! Translated as the “Women Island”, this name originates back over 1500 years and was part of a Mayan province called Ekab. Isla Mujeres was dedicated as a sanctuary and temple for the goddess Ixchel, the goddess of fertility, happiness, medicine, and the moon. The temple dedicated to goddess Ixchel can still be seen and appreciated on the southern end of the island where the lighthouse is located as well. "
            },
            {
                "paragraph": "A ferry ride to Isla Mujeres is 300 pesos (roughly $15 USD) roundtrip, or half the cost for a one way ticket and only takes a 15 minute ride departing from GranPuerto, Puerto Juarez (Ultramar service) in Cancun everyday from 5am-11:30pm. There are 3 other locations (Playa Tortugas, Playa Caracol, and Playa Embarcadero) you can use to depart to Isla Mujeres from Cancun’s hotel zone but this will cost you about double the price."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_9.jpg",
                "subtext": "Enjoy a ferry ride to Isla Mujeres while admiring the beautiful, clear blue waters that surround you"
                
            },
            {
                "paragraph": "Isla Mujeres offers experiences ranging from the basics of enjoying its’ beautiful beaches, exploring vendor shops, to activities such as snorkeling, zip lining and all-inclusive resorts; whatever you’re looking to see and do you will find it and love it here without breaking the bank!"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_10.jpg",
                "subtext": "Arrive to explore Isla Mujeres after you’re done exploring you can relax and unwind at a park"
            },
            {
                "subHeading": "Drive golf carts around Isla Mujeres" 
            },
            {
                "paragraph": "Price: $20-40 USD"
            },
            {
                "paragraph": "Once you’re in Isla Mujeres we recommend renting golf carts to drive around and sightsee the island, making pit stops anywhere you find interesting. Exploring around the island usually takes a bit over an hour, although we recommend at the very least a 2 hour rental to ensure a pleasurable and unrushed time cruising around the island. Golf cart rentals for 2-4 persons can range from around $20-40USD for hourly rentals depending on the comfort and luxury of your rental cart."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_11.jpg",
                "subtext": "Golf carts are the recommended way of exploring throughout the entire island, and can carry up to 4 persons"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_12.jpg",
                "subtext": "Enjoy the views while exploring throughout Isla Mujeres"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_13.jpg",
                "subtext": "Feel free to stop at points of interest and take pictures while exploring Isla Mujeres"
            },
            {
                "paragraph": "Make sure to visit Playa Norte and enjoy the clear waters and soft sands! This is a great spot for snorkeling and seeing some colorful and exotic fishes!"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_14.jpg",
                "subtext": "Snorkel in Playa Norte and see amazing fishes in clear blue waters"
            },
            {
                "subHeading": "Visit a turtle sanctuary in Isla Mujeres" 
            },
            {
                "paragraph": "Price: $3-$5 USD"
            },
            {
                "paragraph": "Fall in love with turtles when you visit Tortugranja, a turtle farm and conservation center helping turtles develop into adulthood to be released into the ocean. For just a $3 entry fee you can help support this conservation center and enjoy seeing green turtles, albino turtles, crabs, conchins, and urchins."
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_15.jpg",
                "subtext": "Albino turtles swimming in water at the turtle farm"
            },
            {
                "image": "https://imagestravelblog.s3.us-east-2.amazonaws.com/FBFEYLC_5/FBFEYLC_5_16.jpg",
                "subtext": "Turtles are raised in safe, humane environments and then released into the ocean"
            },
            {
                "paragraph": "We hope these recommended free and budget friendly tips make for amazing experiences on your next trip to Cancun! For more inspiration on your trip to Cancun visit our other articles on visiting Yucatan Peninsula for further inspiration!"
            },
            {
                "paragraph": "Happy travels from Dos Outdoors!"
            },
            
        ]
    }
];

export default articles;