import React from 'react';

const ArticleTextListItem = ({key,props}) =>{

    if(props.linkURL === undefined){
        return(<li item_no={key}>{props}</li>)
    // }else if(props.joinWord != undefined){
    //     return(
    //     <li item_no={key}>
    //         <a href={props.text[0]} target="_blank">{props.text[1]}</a>
    //         <p> {props.joinWord} </p>
    //         <a href={props.text[2]} target="_blank">{props.text[3]}</a>
    //     </li>)
    }else{
        return (<li item_no={key}><a href={props.linkURL} target="_blank">{props.text}</a></li>);
    }  
    
};
export default ArticleTextListItem;