import React from 'react';

const ArticlesPageImageSquare = ({articleLink, articleImage}) =>(
    <>
        <a href={articleLink}>
        <div class="padding_container">
                <div className="aspect_1_75">
                    <div className="aspectBox">
                        <img src={articleImage}></img>          
                    </div>
                </div>
            </div>
        </a>
    </>
);

export default ArticlesPageImageSquare;