import React from 'react';
import ArticleTextListItem from './ArticleTextListItem'

const ArticleTextListSquare = ({listItems}) => {
    return(
    <>
    <ul className="article_text_list hollow_square_list">
        {listItems.map((item, key) =>
            <ArticleTextListItem key={key} props={item}/>
        )}
    </ul>
    </>
    )

}

export default ArticleTextListSquare;