import React from 'react';
import ResponsiveSquare from './ResponsiveSquare';

const ResponsiveArticleRow = ({articles}) =>(
    <>
         <div className="latest_post_container">
            {/* {articleData.map((article, key)=>(  */}
            
            
            <div class="left_latest_post">
                <ResponsiveSquare articleData={articles} number={0}/>
            </div>
            
            <div class="middle_latest_post">
                <ResponsiveSquare articleData={articles} number={1}/>
            </div>

            <div class="right_latest_post">
                <ResponsiveSquare articleData={articles} number={2}/>
            </div>
            {/* ))} */}
         </div>
         
    
    </>
);

export default ResponsiveArticleRow;