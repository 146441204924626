import React from 'react';
import { Link } from 'react-router-dom';

const BurgerMenu =({open, setOpen}) =>{
    let menu_class= open ? "visibleMenu" : "invisMenu";
   return( 
    <>
        <div id="BurgerMenu" className={menu_class} open={open}>
            <a href="/">
                <span role="img" aria-label="home"></span>
                Home
            </a>
            <a href="/about">
                <span role="img" aria-label="about us"></span>
                About us
            </a>
            <a href="/articles-list">
                <span role="img" aria-label="articles"></span>
                Articles
            </a>
        </div>
        </>
   );
};
export default BurgerMenu;