import React from 'react';

const ButtonLink = ({name, link}) =>(
    <>
        <a className="redirect_button" href={link}>
            {name}
        </a>
    </>
);

export default ButtonLink;